import { useEffect, useCallback } from "react";
import _ from "lodash";
// catch errors and loading status
export const hook = (fnInput, fnOutput) => {
  // clear debounce when unmount
  useEffect(() => {
    return () => {
      debouncedCheck.cancel();
    };
  }, []);

  const debouncedCheck = useCallback(
    _.debounce((...params) => {
      fnOutput(...params);
    }, 500),
    [] // Dependencies array is empty to create this function only once
  );

  const onInput = (...params) => {
    fnInput(...params);
    // examine if the email is unique after 1 second
    _.debounce(() => {
      debouncedCheck(...params);
    }, 500)();
  };

  return { onInput };
};

export default hook;
