import React, { useState, useRef } from "react";
import Carousel from "atom/Carousel";
import style from "./style.scss";
import cn from "classnames";
import BannerContent from "../../molecule/BannerContent";

const Banner = ({
  background,
  content,
  home,
}) => (
  <div
    className={cn(
      style.root,
      content ? style.withContent : null,
      home ? style.rootHome : ""
    )}
    style={{ backgroundImage: `url(${background})` }}
  ></div>
);

export default ({ home, background, ariaLabelPause }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [forceIndex, setForceIndex] = useState(-1)


  const slider = useRef(null);
  const [sliderPaused, setSliderPaused] = useState(false);
  const [sliderFocus, setSliderFocus] = useState(false);

  return (
    <div className={cn(style.bannerContainer)}>
      <Carousel
        className={cn(style.banner, home ? style.bannerHome : "")}
        ref={slider}
        paused={sliderPaused}
        onPaused={setSliderPaused}
        pauseAria={ariaLabelPause}
        focused={sliderFocus}
        currentIndex={currentIndex}
        forceIndex={forceIndex}
        setCurrentIndex = {setCurrentIndex}
        onBeforeChange={(i) => {
          setCurrentIndex(i);
        }}
        settings={{
          dots: false,
          fade: true,
          customPaging: (_i) => <div style={{ height: 8, width: 28 }} />,
          // afterChange: setCurrentIndex,
        }}
      >
        {home ? (
          home.map((value, i) => (
            <Banner
              {...value}
              key={i}
              content={true}
              isVisible={currentIndex === i}
              onFocus={() => {
                setSliderFocus(true);
                // setSliderPaused(true)
              }}
              onBlur={() => {
                setSliderFocus(false);
                // setSliderPaused(false)
              }}
              home={home}
            />
          ))
        ) : (
          <Banner background={background} />
        )}
      </Carousel>
      <div className={cn(style.contentContainer)}>
        <div className={cn(style.contentCardList)} 
        style={{gridTemplateColumns: `repeat(${home?.length}, 1fr)`}}
        >
          {home?.map((contentBox, i) => {
            if (!contentBox) return null;
            return (
              <BannerContent
                onFocus={() => {
                  setSliderFocus(true);
                  // setSliderPaused(true)
                }}
                onBlur={() => {
                  setSliderFocus(false);
                  // setSliderPaused(false)
                }}
                onMouseOver={() => {
                  setForceIndex(i);
                }}
                onMouseOut={() => {
                  setForceIndex(-1);
                }}                
                key={`content_box_${i}`}
                {...contentBox}
                isActive={currentIndex === i}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
