import Cookies from "universal-cookie";

const ACCESS_CODE_KEY = "OPTUMDEMO_ACCESS_CODE";
const accesscodeCookie = new Cookies();

const AccessCodeProvider = {
  getAccessCode: () => {
    return accesscodeCookie.get(ACCESS_CODE_KEY, { path: "/" });
  },
  setAccessCode: code => {
    accesscodeCookie.set(ACCESS_CODE_KEY, code, { path: "/" });
  },
  removeAccessCode: () => {
    accesscodeCookie.remove(ACCESS_CODE_KEY, { path: "/" });
  },
};

export default AccessCodeProvider;
