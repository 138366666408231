import NicknameProvider from "provider/NicknameProvider";

export const LINKTYPES = {
  EXTERNAL: "EXTERNAL",
  ABSOLUTE: "ABSOLUTE",
  RELATIVE: "RELATIVE",
  PDF: "EXTERNAL",
  MAIL: "EXTERNAL",
  PHONE: "EXTERNAL",
};

const preventScrollListener = e => {
  // e.stopPropagation();
};

export const lockBody = isUnLock => {
  if (isUnLock) {
    // op1
    document.body.style.overflow = "hidden";
    const currentScroll = document.documentElement.scrollTop;
    window.scroll(0, 0);
    const root = document.getElementById("root");
    root.style.height = "100vh";
    root.style.width = "100vw";
    root.style.overflow = "auto";
    root.scrollTop = currentScroll;
    root.addEventListener("wheel", preventScrollListener);
    root.addEventListener("mousewheel", preventScrollListener);
    root.addEventListener("DOMMouseScroll", preventScrollListener);

    // op2
    // const targetElement = ref.current
    // disableBodyScroll(targetElement);
  } else {
    // op2
    // const targetElement = ref.current
    // enableBodyScroll(targetElement);

    // // op1
    document.body.style.overflow = "auto";
    document.body.style.position = null;
    const root = document.getElementById("root");
    const currentScroll = root.scrollTop;
    root.style.height = null;
    root.style.width = null;
    root.style.overflow = null;
    root.scrollTop = 0;
    window.scroll(0, currentScroll);

    root.removeEventListener("wheel", preventScrollListener);
    root.removeEventListener("mousewheel", preventScrollListener);
    root.removeEventListener("DOMMouseScroll", preventScrollListener);
  }
};

export const logout = callback => {
  localStorage.clear();
  NicknameProvider.removeNickname();
  callback();
};

export const getLinktype = (link) => {
  const reg = new RegExp("^(?:[a-z]+:)?//", "i");

  if (link?.toLowerCase().lastIndexOf(".pdf") != -1) {
    return LINKTYPES.PDF;
  }

  if (link?.toLowerCase().indexOf("mailto:") === 0) {
    return LINKTYPES.MAIL;
  }

  if (link?.toLowerCase().indexOf("tel:") === 0) {
    return LINKTYPES.PHONE;
  } else if (reg.test(link)) {
    return LINKTYPES.EXTERNAL;
  } else if (link?.indexOf("/") === 0) {
    return LINKTYPES.ABSOLUTE;
  } else {
    return LINKTYPES.RELATIVE;
  }
};
