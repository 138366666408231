import React, { useState } from "react";
import cn from "classnames";
import Modal from "atom/Modal";
import Icon from "atom/Icon";
import SelectCountry from "atom/SelectCountry";
import benefitsItems from "data/resources";

// styles
import style from "./style.scss";
import { Heading } from "atom/Typography";

export default ({ className, resourceKey, onClose }) => {
  const [selectedCountry, setSelectedCountry] = useState("United States");
  const resourceItem = benefitsItems[resourceKey];

  return resourceKey ? (
    <Modal
      id={"resource"}
      contentClass={cn(className, style.root)}
      {...{ onClose }}
    >
      <Heading className={style.header} type={"h2"} centered>
        {resourceItem?.icon && <Icon icon={resourceItem?.icon} />}
        <span>{resourceItem?.title}</span>
      </Heading>
      {resourceItem?.showCountry && (
        <SelectCountry
          selected={selectedCountry}
          handleChange={e => setSelectedCountry(e.target.value)}
        />
      )}
      <div className={cn(className, style.info)}>
        {resourceItem?.phone && (
          <a href={`tel:${resourceItem.phone}`}>
            <div className={style.phone_wrapper}>
              <span>Phone</span>
              <Icon icon={"phone"} className={style.icon} />
            </div>
          </a>
        )}
        {resourceItem?.link && (
          <a href={resourceItem.link} target="_blank">
            <div className={style.website_wrapper}>
              <span>Website</span>
              <Icon icon={"cardarrow"} className={style.icon} />
            </div>
          </a>
        )}
        {resourceItem?.content && (
          <div
            className={style.details_wrapper}
            dangerouslySetInnerHTML={{
              __html: resourceItem.content,
            }}
          ></div>
        )}
      </div>
    </Modal>
  ) : null;
};
