import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "atom/Button";
import Journey from "assets/images/journey.svg";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import { logout } from "lib/utils";
import Footer from "molecule/Footer";
import Header from "molecule/Header";
import Chatbot from "organism/Chatbot";
import Settings from "organism/Settings";
import Tutorial from "organism/Tutorial";
import NicknameProvider from "provider/NicknameProvider";
import SettingsProvider from "provider/SettingsProvider";
import BenefitsandPrograms from "screen/BenefitsandPrograms";
import AccessCodeProvider from "provider/AccessCodeProvider";
import Benefits from "./Benefits";
import HealthJourneys from "./HealthJourneys";
import Spotlight from "./Spotlight";
import FAQs from "./FAQs";
import style from "./style.scss";
import cn from "classnames";
import CTA from "./CTA";
import Login from "molecule/Login";
import Register from "molecule/Register";
import Modal from "atom/Modal";
import ModalCall from "organism/ModalCall";
import ModalChat from "organism/ModalChat";
import Bot from "organism/Chatbot/Bot";

const Page = () => {
  let location = useLocation();
  const [mode, setMode] = useState(null);
  const [filter, setFilter] = useState("All");
  const [showChatbot, setShowChatbot] = useState(null);
  const [openBenefits, setOpenBenefits] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [openCall, setOpenCall] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [openTutorial, setOpenTutorial] = useState(false);
  const avatars = SettingsProvider.getAvatars();
  const countrylang = SettingsProvider.getCountryLanguage();
  const Nickname = NicknameProvider.getNickname();
  const AccessCode = AccessCodeProvider.getAccessCode();
  const navigate = useNavigate();

  const handleSubmit = value => {
    NicknameProvider.setNickname(value);
    setMode("");
  };

  useEffect(() => {
    localStorage.removeItem("demo_openedmodal");

    if (!AccessCode) {
      navigate("/company");
    }

    if (!Nickname) {
      setMode("login");
    }

    if (!avatars)
      SettingsProvider.setAvatars({ user: "user0", chat: "chat-0" });
    if (!countrylang) {
      SettingsProvider.setCountryLanguage({
        country: "United States",
        language: "English (US)",
      });
    }

    const hash = location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(decodeURI(id));
      if (element)
        setTimeout(() => {
          const yCoordinate =
            element.getBoundingClientRect().top + window.pageYOffset;
          const yOffset = -100;
          window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
        }, 200);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.pathname == "/benefits-and-programs" && !openBenefits) {
      setOpenBenefits(true);
    } else if (location.pathname == "/" && openBenefits) setOpenBenefits(false);
  }, [location.pathname]);

  useEffect(() => {
    if (filter != "All") navigate("/benefits-and-programs");
  }, [filter]);

  const handleTestClear = () => {
    AccessCodeProvider.removeAccessCode();
    logout(() => navigate("/company"));
  };

  function journeyCardClick(category) {
    setFilter(category);
  }

  return (
    <div>
      {mode && (
        <Modal
          id={"login"}
          contentClass={cn(style.login_container)}
          isCloseButton={false}
          withClose={false}
        >
          {mode === "login" && (
            <Login
              onSubmit={handleSubmit}
              onRegister={() => setMode("register")}
            />
          )}
          {mode === "register" && (
            <Register
              onSubmit={handleSubmit}
              onLogin={() => setMode("login")}
            />
          )}
        </Modal>
      )}
      <Chatbot
        show={showChatbot}
        // show={true}
        onClose={() => setShowChatbot(false)}
      />
      <Header
        onOpenSettings={() => setOpenSettings(true)}
        onChatbot={() => setShowChatbot(true)}
        showHelp={() => setShowContact(true)}
        user={avatars.user}
        nickname={Nickname}
      />

      {/* {openTutorial && (
        <Tutorial
          onClose={() => setOpenTutorial(false)}
          openChat={() => {
            setOpenTutorial(false);
            setShowChatbot(true);
          }}
        />
      )} */}
      {openSettings && <Settings onClose={() => setOpenSettings(false)} />}
      {openCall && <ModalCall onClose={() => setOpenCall(false)} />}
      {openChat && <ModalChat onClose={() => setOpenChat(false)} />}
      {openBenefits ? (
        <BenefitsandPrograms
          onClick={() => navigate("/")}
          initialFilter={filter}
        />
      ) : (
        <>
          <PageCenter
            id={"Home"}
            rootClass={cn(style.rootbanner)}
            contentClass={cn(style.banner)}
          >
            <div className={cn(style.text)}>
              <Heading>
                Start your personalized journey to emotional wellbeing
              </Heading>
              <Heading type={"h5"} className={cn(style.subtitle)}>
                Support Finder is a globally accessible interactive platform,
                providing anonymous guidance to users regarding Optum’s
                emotional wellbeing benefits.
              </Heading>
              <div className={cn(style.bannerButtons)}>
                <Button
                  text={"Get guided help"}
                  onClick={() => setShowChatbot(true)}
                />
                <Button
                  light
                  text={"Show my benefits"}
                  onClick={() => navigate("/benefits-and-programs")}
                />
              </div>
            </div>
            <Journey />
          </PageCenter>
          <CTA call={() => setOpenCall(true)} chat={() => setOpenChat(true)} />
          <Spotlight />
          <HealthJourneys onClick={journeyCardClick} />
          <FAQs />
        </>
      )}
      <Footer />
      <button
        onClick={handleTestClear}
        style={{ position: "absolute", bottom: 0 }}
      >
        Test Button - logout
      </button>
    </div>
  );
};

export default Page;
