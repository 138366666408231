export default [
  {
    id: "none",
    message: "That's completely understandable!",
    trigger: "support-q1",
  },
  {
    id: "none-2",
    message: "Sometimes it's hard to define an issue  you're having.",
    trigger: "none-options",
  },
  {
    id: "none-options",
    options: [
      {
        value: "1",
        label: "Would learning more about your benefits be beneficial?",
        trigger: "none-learning",
      },
      {
        value: 2,
        label: "I want to feel better, but i'm not sure where to start.",
        trigger: "none-start",
      },
      {
        value: 3,
        label:
          " Could you please provide me with some commonly used suggestions?",
        trigger: "none-suggestions",
      },
    ],
  },
  {
    id: "none-learning",
    message:
      "Learning more about your benefits can be a great place to start your wellness journey!",
    trigger: "none-learning-2",
  },
  {
    id: "none-learning-2",
    message: "Click below to learn more about your benefit options",
    trigger: "none-learning-3",
  },
  {
    id: "none-learning-3",
    message: "I hope to see you back soon!",
    trigger: "resources",
    //Benefits card
  },
  {
    id: "none-start",
    message: "That's alright, {name}.",
    trigger: "none-start-2",
  },
  {
    id: "none-start-2",
    message:
      "Wanting to get better is a great first step when starting your wellness journey!",
    trigger: "general-question-1",
  },
  {
    id: "none-suggestions",
    message: "Yes of course I can provide you with some suggestions!",
    trigger: "general-question-3",
  },
  {
    id: "none-suggestions-no",
    message: "That's okay",
    trigger: "none-suggestions-no-2",
  },
  {
    id: "none-suggestions-no-2",
    message: "I can still provide some of the most commonly used services",
    trigger: "none-suggestions-no-options",
  },
  {
    id: "none-suggestions-no-options",
    options: [
      {
        value: "1",
        label: "AbleTo - A self-care evidence based mental health tool",
        trigger: "",
        // trigger: "ableTo",
      },
      {
        value: 2,
        label: "Uptime - Digital on the go training & self-care",
        trigger: "",
        // trigger: "uptime",
      },
      {
        value: 3,
        label: "Supportiv - An anonymous peer-to-peer support network",
        trigger: "",
        // trigger: "supportiv",
      },
      {
        value: 4,
        label: "None of these sound quite right",
        trigger: "",
        // trigger: "none-none",
      },
    ],
  },
];
