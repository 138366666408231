import Cookies from "universal-cookie";

const COUNTRYLANG_KEY = "OPTUMDEMO_COUNTRYLANG";
const AVATARS_KEY = "OPTUMDEMO_AVATARS";

const SettingsProvider = {
  getCountryLanguage: () => {
    const item = localStorage.getItem(COUNTRYLANG_KEY);
    if (item) return JSON.parse(item);
    else return false;
  },
  setCountryLanguage: val => {
    localStorage.setItem(COUNTRYLANG_KEY, JSON.stringify(val));
  },
  getAvatars: () => {
    const item = localStorage.getItem(AVATARS_KEY);
    if (item) return JSON.parse(item);
    else return false;
  },
  setAvatars: val => {
    localStorage.setItem(AVATARS_KEY, JSON.stringify(val));
  },
  getChatbot: () => {
    const item = localStorage.getItem(AVATARS_KEY);
    if (item) {
      const parsed = JSON.parse(item);
      return parsed["chat"];
    } else return false;
  },
};

export default SettingsProvider;
