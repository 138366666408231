import React, { useState } from "react";
// import ChatBot from "react-chatbotify";
import Modal from "atom/Modal";
import Form from "./Form";
import style from "./style.scss";
import cn from "classnames";
import { Heading } from "atom/Typography";

const styles = {
  bodyStyle: {
    paddingTop: 12,
  },
  chatWindowStyle: { height: "96vh" },
  botBubbleStyle: {
    backgroundColor: "var(--palette-warm-white)",
    borderRadius: 8,
    color: "var(--palette-dark-blue)",
    padding: "6px 12px",
  },
  headerStyle: {
    background: "none",
    color: "var(--palette-dark-blue)",
    backgroundColor: "var(--palette-white)",
  },
};

// addEventListener("DOMNodeInserted",this.onNodeInserted),window.addEventListener("resize",this.onResize))

const ModalChat = ({ onClose }) => {
  const [formInput, setFormInput] = useState([]);

  const settings = {
    general: {
      fontFamily: "var(--font-family)",
      embedded: true,
      showFooter: false,
    },
    // chatHistory: { storageKey: "playground" },
    chatHistory: { disabled: true },
    header: {
      title: <Heading type="h4">Chat</Heading>,
      showAvatar: false,
      closeChatIcon: "x",
      buttons: [<div onClick={onClose}>X</div>],
    },
    notification: {
      disabled: true,
    },
  };

  const flow = {
    start: {
      message: async (params) => {
        await params.injectMessage("Hello, welcome to chat.");
        await params.injectMessage(
          "If you are seeking emotional support, please call us at the number listed on liveandworkwell.com"
        );
        await params.injectMessage(
          "Please tell us about yourself so we can connect you with the right person."
        );
      },
      transition: { duration: 500 },
      path: "chat-form",
      chatDisabled: true,
    },
    "chat-form": {
      component: <Form {...{ formInput, setFormInput }} />,
      transition: { duration: 500 },
      path: "review",
      chatDisabled: true,
    },
    review: {
      message:
        "Just to review, generally speaking our conversations are confidential. There are a few exceptions to this rule. If you express thoughts or plans of harming yourself or others, if a child or vulnerable adult is at risk of abuse or neglect, or if a judge or legal authority requested the information.",
      path: "welcome",
    },
    welcome: {
      message:
        "Welcome to you {name}, My name is Jennifer. Do you have any questions about the confidentiality policy?",
      path: "response",
    },
    response: {
      message: "Great. How can I assist you today?",
      path: "response1",
    },
    response1: {
      message:
        "I can definitely assist with that. I know grief can be overwhelming at times, it you're interested, I'm an EWS Specialist, and I can offer brief in the moment support to you today. Would you like to talk to me more about what you've been experiencing?",
      path: "response2",
    },
    response2: {
      message:
        "Ok, we can certainly go ahead and get you set-up. You have 8, no-cost, EWS sessions available to work with a counselor in your state. I can assist in finding a counselor in your state and scheddule a complimentary, 10 minute, consultation to have within 2 days. Would you like to get set-up with that option?",
      path: "response3",
    },
    response3: {
      message:
        "Very good. Do you have a preference regarding gender or ethnicity? Is there a day of the week that just won't work for you? Is there a preferred time of day?",
      path: "response4",
    },
    response4: {
      message:
        "Yes, I found a 10-minute session for you on May 21st at 4:00 PM CDT with William Capton MA LPC. Does that work for you?",
      path: "response5",
    },
    response5: {
      message:
        "Great. You will receive an email confirming your consultation. Is there anything else I could assist with?",
      path: "response6",
    },
    response6: {
      message:
        "Thank you for reaching out! Please feel free to reach out again, we are available 24 hours a day, 7 days a week.",
      path: "start",
    },
  };

  return (
    <Modal
      id={"chatcta"}
      finalClose={onClose}
      containerClass={cn(style.modal)}
      contentClass={cn(style.content)}
      isCloseButton={false}
      withClose={false}
    >
      {/* <ChatBot settings={settings} flow={flow} styles={styles} /> */}
    </Modal>
  );
};

export default ModalChat;
