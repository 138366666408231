import React from "react";
import none from "./options/none";
import wellbeing from "./options/wellbeing";
import lifestyle from "./options/lifestyle";
import administrative from "./options/administrative";
import support from "./options/support";
import benefits from "./options/benefits";
import general from "./general";

export default [
  {
    id: "intro-1",
    message: "Hello, {name} !👋",
    trigger: "intro-2",
  },
  {
    id: "intro-2",
    message: "I'm a digital assistant built by Optum",
    trigger: "intro-3",
  },
  {
    id: "intro-3",
    message:
      "I'm here to help you navigate the many resources your organization has for your well-being.",
    trigger: "intro-4",
  },
  {
    id: "intro-4",
    message: "What can I help you with today?",
    trigger: "main-options",
  },
  {
    id: "main-options",
    options: [
      { value: 1, label: "I'm not sure or none of these", trigger: "none" },
      {
        value: 2,
        label: "Improve mental wellbeing",
        trigger: "wellbeing",
      },
      {
        value: 3,
        label: "Improve my daily lifestyle",
        trigger: "lifestyle",
      },
      {
        value: 4,
        label: "I need help with an administrative issue",
        trigger: "administrative",
      },
      {
        value: 5,
        label: "Talk to someone or get support now",
        trigger: "support",
      },
      {
        value: 6,
        label: "Learn more about my benefits",
        trigger: "benefits",
      },
    ],
  },
  ...general,
  ...none,
  ...wellbeing,
  ...lifestyle,
  ...administrative,
  ...support,
  ...benefits,
];
