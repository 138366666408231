import React, { useEffect, useRef } from "react";
import ChatBot from "react-simple-chatbot";
import intro from "data/new/intro";
import eval from "data/new/eval";
import journeys from "data/old/journeys";
import wellbeing from "data/new/options/wellbeing";
import ResourceCards from "molecule/ResourceCards";
import NicknameProvider from "provider/NicknameProvider";
import style from "./style.scss";
import cn from "classnames";

const bubbleStyle = {
  borderRadius: 8,
  color: "var(--palette-dark-blue)",
  padding: "6px 12px",
  boxShadow: "none",
};

export const Bubble = ({ text }) => (
  <div className={cn(style.evalBubble)} style={bubbleStyle}>
    {text}
  </div>
);

function replacePatternWithObjectValues(string, obj) {
  return string.replace(/{([^{}]+)}/g, function(match, key) {
    return obj[key] || match;
  });
}

const Bot = ({
  triggerStep,
  onClose,
  className,
  setResourceKey,
  journeyStep,
  id,
}) => {
  const introSteps =
    triggerStep == "pathways"
      ? [...wellbeing]
      : triggerStep == "journeys"
      ? [
          {
            id: "journeys",
            message: "Hi there!",
            trigger: journeyStep,
          },
        ]
      : [...intro];
  const stepCustomValues = {
    name: NicknameProvider.getNickname(),
  };

  function openResource(link) {
    window.open(link, "_blank");
  }

  function genResources(id, keys) {
    return {
      id: id,
      component: (
        <ResourceCards
          keys={keys}
          smaller={true}
          onClick={item => setResourceKey(item)}
        />
      ),
      trigger: "resource-cards-closing",
    };
  }

  const resourceSteps = [
    genResources("resource-cards", ["liveandworkwell"]),
    genResources("resources-cards-wellbeing-happier-positive", [
      "ableTo",
      "calm",
      "uptime",
      "ewsSupport",
    ]),
    genResources("resources-cards-wellbeing-happier-negative", [
      "providerSearch",
      "ewsSupport",
      "ewsDigitalAuth",
    ]),
    genResources("resources-cards-wellbeing-happier-later", [
      "ableTo",
      "ewsSupport",
      "calm",
    ]),
    genResources("resources-cards-administrative-legal-positive", [
      "legal",
      "ableTo",
      "ewsSupport",
    ]),
    genResources("resources-cards-administrative-legal-negative", [
      "legal",
      "providerSearch",
      "ewsSupport",
    ]),
    genResources("resources-cards-administrative-legal-no", [
      "legal",
      "findHelp",
      "ewsSupport",
    ]),
    genResources("resources-cards-support-support-providersearch-no", [
      "providerSearch",
      "ewsSupport",
    ]),

    //old
    genResources("resources-cards-wellbeing-resilience", [
      "talkspace",
      "uptime",
      "ableTo",
      "rethink",
    ]),
    genResources("resources-cards-wellbeing", [
      "ableTo",
      "uptime",
      "emindful",
      "supportiv",
    ]),
    genResources("resources-cards-lifestyle", [
      "ableTo",
      "uptime",
      "emindful",
      "liveandworkwell",
    ]),
    genResources("resources-cards-assistance", [
      "legal",
      "coaching",
      "rethink",
      "liveandworkwell",
    ]),

    genResources("resources-cards-assistance-financial", ["coaching"]),
    genResources("resources-cards-assistance-parenting", ["rethink"]),
    genResources("resources-cards-assistance-management", ["liveandworkwell"]),
    genResources("resources-cards-support", ["liveandworkwell"]),
    genResources("resources-cards-notsure", [
      "ableTo",
      "uptime",
      "emindful",
      "liveandworkwell",
    ]),
    genResources("resources-cards-providersearch", ["providerSearch"]),
    genResources("resources-cards-digitalauth", ["digitalAuth"]),
    genResources("resources-cards-eapphone", ["eapPhone"]),
    genResources("resources-cards-behavioral", ["behavioral"]),
    {
      id: "resource-cards-closing",
      message:
        "Explore those resources and remember you can contact help 24/7 by clicking Contact us on the main page",
      trigger: "",
    },
    genResources("journey-resources-selfcare", [
      "ableTo",
      "emindful",
      "uptime",
    ]),
    genResources("journey-resources-therapy", [
      "premiumNetwork",
      "talkspace",
      "providerSearch",
      "eapPhone",
      "digitalAuth",
    ]),
    genResources("journey-resources-financial", ["coaching", "legal"]),
    genResources("journey-resources-coaching", [
      "ableTo",
      "emindful",
      "coaching",
    ]),
    genResources("journey-resources-management", ["eapPhone", "management"]),
    genResources("journey-resources-peer", ["supportiv"]),
    genResources("journey-resources-ewsbenefits", [
      "digitalAuth",
      "eapPhone",
      "premiumNetwork",
    ]),
    genResources("journey-resources-literacy", [
      "lawwDeepLink",
      "liveandworkwell",
    ]),
  ];

  const steps = [
    ...introSteps,
    ...intro,
    ...journeys,
    ...resourceSteps,
    ...eval,
  ].map(step => {
    const _s = { ...step };
    if (_s.message) {
      _s.message = replacePatternWithObjectValues(
        step.message,
        stepCustomValues
      );
    }
    return _s;
  });

  const handleEnd = e => {
    setTimeout(() => {
      if (e.values.at(-1) == "popout") {
        window.open(
          "https://supportfinder.healthtek.me/#/benefits-and-programs",
          "_blank",
          "noopener,noreferrer"
        );
      }

      onClose();
    }, 1000);
  };

  const modalRef = useRef(null);

  useEffect(() => {
    if (modalRef.current) {
      const modalElement = modalRef.current.querySelector(".rsc-content");
      const handleScroll = event => {
        const { scrollTop, scrollHeight, clientHeight } = modalElement;
        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && event.deltaY < 0) event.preventDefault();

        // prevent scroll down, but allow scroll up
        if (scrollTop + clientHeight >= scrollHeight && event.deltaY > 0)
          event.preventDefault();
      };

      const handleTouchMove = event => {
        if (!event.cancelable) return;
        const { scrollTop, scrollHeight, clientHeight } = modalElement;
        const touch = event.touches[0];

        // prevent scroll up, but allow scroll down
        if (scrollTop === 0 && touch.clientY > touchStartY)
          event.preventDefault();

        // prevent scroll down, but allow scroll up
        if (
          scrollTop + clientHeight >= scrollHeight &&
          touch.clientY < touchStartY
        )
          event.preventDefault();
      };

      const handleTouchStart = event => {
        touchStartY = event.touches[0].clientY;
      };

      let touchStartY = 0;

      // get the modal element inside ref.current, classname is "rsc-content"
      modalElement.addEventListener("wheel", handleScroll);
      modalElement.addEventListener("touchstart", handleTouchStart);
      modalElement.addEventListener("touchmove", handleTouchMove);

      // handle browser deprecated event causes scroll issue
      const observer = patchOnScrollIssue(modalElement);
      return () => {
        modalElement.removeEventListener("wheel", handleScroll);
        modalElement.removeEventListener("touchstart", handleTouchStart);
        modalElement.removeEventListener("touchmove", handleTouchMove);

        // handle browser deprecated event causes scroll issue
        if (observer) {
          observer.disconnect();
        }
      };
    }
  }, [modalRef.current]);

  return (
    <div
      ref={modalRef}
      style={{
        height: "100%",
        overflowY: "auto",
        padding: "1px 0px",
        boxSizing: "border-box",
      }}
    >
      <ChatBot
        handleEnd={e => handleEnd(e)}
        hideHeader={true}
        hideBotAvatar={true}
        hideUserAvatar={true}
        enableSmoothScroll={true}
        userDelay={100}
        botDelay={1500}
        bubbleStyle={bubbleStyle}
        footerStyle={{ display: "none" }}
        steps={steps}
        cache={true}
        cacheName={id}
        className={cn(style.chatbot, className)}
      />
    </div>
  );
};

export default Bot;

const patchOnScrollIssue = modalElement => {
  // Callback function to handle mutations
  const mutationCallback = mutationsList => {
    for (let mutation of mutationsList) {
      if (mutation.type === "childList" && mutation.addedNodes.length > 0) {
        modalElement.scrollTop = modalElement.scrollHeight;
      }
    }
  };

  // Create a MutationObserver instance and pass the callback function
  const observer = new MutationObserver(mutationCallback);
  if (modalElement) {
    observer.observe(modalElement, {
      childList: true,
      subtree: true,
      characterData: true,
    });
  }

  // Cleanup function to disconnect the observer when the component unmounts
  return observer;
};
