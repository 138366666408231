import React, { useState } from "react";
import Button from "atom/Button";
import Input, { Select } from "atom/Input";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const fields = [
  {
    type: "select",
    id: "topic",
    optionDisabled: "Select a topic",

    label: "Chat topic",
    options: [
      {
        title: "Benefits and authorizations",
        value: "BenefitsAndAuthorizations",
      },
      {
        title: "Something else",
        value: "SomethingElse",
      },
    ],
  },
  {
    type: "text",
    id: "firstname",
    placeholder: "",
    label: "First name",
  },
  {
    type: "text",
    id: "lastname",
    placeholder: "",
    label: "Last name",
  },
  {
    type: "date",
    id: "birthdate",
    placeholder: "",
    label: "Date of birth",
    format: "mm/dd/yyyy",
  },
  {
    type: "email",
    id: "email",
    placeholder: "",
    label: "Email address",
    format: "name@email.com",
  },
  {
    type: "select",
    id: "state",
    optionDisabled: "Select a state",
    label: "State",
    options: [
      {
        title: "Alabama",
        value: "AL",
      },
      {
        title: "Alaska",
        value: "AK",
      },
      {
        title: "Arizona",
        value: "AZ",
      },
      {
        title: "Arkansas",
        value: "AR",
      },
      {
        title: "California",
        value: "CA",
      },
      {
        title: "Colorado",
        value: "CO",
      },
      {
        title: "Connecticut",
        value: "CT",
      },
      {
        title: "Delaware",
        value: "DE",
      },
      {
        title: "District Of Columbia",
        value: "DC",
      },
      {
        title: "Florida",
        value: "FL",
      },
      {
        title: "Georgia",
        value: "GA",
      },
      {
        title: "Hawaii",
        value: "HI",
      },
      {
        title: "Idaho",
        value: "ID",
      },
      {
        title: "Illinois",
        value: "IL",
      },
      {
        title: "Indiana",
        value: "IN",
      },
      {
        title: "Iowa",
        value: "IA",
      },
      {
        title: "Kansas",
        value: "KS",
      },
      {
        title: "Kentucky",
        value: "KY",
      },
      {
        title: "Louisiana",
        value: "LA",
      },
      {
        title: "Maine",
        value: "ME",
      },
      {
        title: "Maryland",
        value: "MD",
      },
      {
        title: "Massachusetts",
        value: "MA",
      },
      {
        title: "Michigan",
        value: "MI",
      },
      {
        title: "Minnesota",
        value: "MN",
      },
      {
        title: "Mississippi",
        value: "MS",
      },
      {
        title: "Missouri",
        value: "MO",
      },
      {
        title: "Montana",
        value: "MT",
      },
      {
        title: "Nebraska",
        value: "NE",
      },
      {
        title: "Nevada",
        value: "NV",
      },
      {
        title: "New Hampshire",
        value: "NH",
      },
      {
        title: "New Jersey",
        value: "NJ",
      },
      {
        title: "New Mexico",
        value: "NM",
      },
      {
        title: "New York",
        value: "NY",
      },
      {
        title: "North Carolina",
        value: "NC",
      },
      {
        title: "North Dakota",
        value: "ND",
      },
      {
        title: "Ohio",
        value: "OH",
      },
      {
        title: "Oklahoma",
        value: "OK",
      },
      {
        title: "Oregon",
        value: "OR",
      },
      {
        title: "Pennsylvania",
        value: "PA",
      },
      {
        title: "Rhode Island",
        value: "RI",
      },
      {
        title: "South Carolina",
        value: "SC",
      },
      {
        title: "South Dakota",
        value: "SD",
      },
      {
        title: "Tennessee",
        value: "TN",
      },
      {
        title: "Texas",
        value: "TX",
      },
      {
        title: "Utah",
        value: "UT",
      },
      {
        title: "Vermont",
        value: "VT",
      },
      {
        title: "Virginia",
        value: "VA",
      },
      {
        title: "Washington",
        value: "WA",
      },
      {
        title: "West Virginia",
        value: "WV",
      },
      {
        title: "Wisconsin",
        value: "WI",
      },
      {
        title: "Wyoming",
        value: "WY",
      },
    ],
  },
];

export default ({ formInput, setFormInput }) => {
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e, name) => {
    setFormInput(prevState => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
  };

  return (
    <div className={cn(style.chatForm)}>
      <Heading type="h5">Tell us more about you</Heading>
      <p>All fields are required.</p>
      {fields.map((val, i) => {
        const { id, type, label, format, placeholder, options } = val;

        return (
          <div className={cn(style.field)} key={i}>
            {type == "select" ? (
              <>
                <label htmlFor={id}>{label}</label>
                <Select
                  optionDisabled={placeholder}
                  items={options}
                  value={formInput[id] || ""}
                />
              </>
            ) : (
              <>
                <label htmlFor={id}>{label}</label>
                <Input
                  className={cn(style.input)}
                  {...{ id, type, placeholder }}
                  value={formInput[id] || ""}
                  onChange={e => handleChange(e, id)}
                  aria-describedby={`${id}_err`}
                  // validation={validation}
                  // onKeyDown={handleKeyDown}
                  required
                />
                <div className={cn(style.format)}>{format}</div>
              </>
            )}
          </div>
        );
      })}

      <Button text="Start chat" onClick={handleSubmit} />
    </div>
  );
};
