import React from "react";
import PageCenter from "atom/PageCenter";
import Anchor from "atom/Anchor";
import style from "./style.scss";
import cn from "classnames";

const copyright = "© 2024 Optum Inc. ";
const links = [
  { label: "Terms of use", url: "https://www.optum.com/terms-of-use.html" },
  { label: "Privacy Policy", url: "https://www.optum.com/privacy-policy.html" },
  { label: "Accessibility", url: "https://www.optum.com/accessibility.html" },
  {
    label: "Cookie Preferences",
    url: "https://www.optum.com/accessibility.html",
  },
];

const Footer = () => (
  <PageCenter rootClass={cn(style.root)} contentClass={cn(style.footer)}>
    <div>
      {copyright}
      <p>
        {links.map(({ label, url }, i) => (
          <label key={label + i}>
            <Anchor href={url}>{label}</Anchor>
          </label>
        ))}
      </p>
    </div>
  </PageCenter>
);

export default Footer;
