import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue, onBack }) => {
  return (
    <div className={cn(style.s2)}>
      <div className={cn(style.formContainer)}>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formTitle)}>
            What is your relationship to the primary policy holder?
          </div>
          <Form.Select aria-label="Select one to continue">
            <option value="">Please select one to continue</option>
            {data.step2.options1.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </Form.Select>
        </div>
        <hr className={cn(style.divider)}/>
        <div className={cn(style.formTitle)}>Your information</div>
        <div className={cn(style.formSubRow)}>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>First name</div>
            <Form.Control type="text" />
          </div>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>Last name</div>
            <Form.Control type="text" />
          </div>
        </div>
        <div className={cn(style.formSubRow)}>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>Street address</div>
            <Form.Control type="text" />
          </div>
        </div>
        <div className={cn(style.formSubRow)}>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>City</div>
            <Form.Control type="text" />
          </div>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>State</div>
            <Form.Select aria-label="Select one to continue">
              <option value="">Choose one</option>
              {data.states.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.value}
                  </option>
                );
              })}
            </Form.Select>
          </div>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>Zip</div>
            <Form.Control type="text" />
          </div>
        </div>

        <div className={cn(style.formSubRow)}>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>Email address</div>
            <Form.Control type="text" placeholder="Optional" />
          </div>
          <div className={cn(style.formItem)}>
            <div className={cn(style.formSubtitle)}>Date of birth</div>
            <Form.Control type="text" />
          </div>          
        </div>
      </div>

      <div className={cn(style.stepButtonContainer)}>
        <div className={cn(style.backButton)} onClick={onBack}>
          <span>Back</span>
        </div>
        <div className={cn(style.continueButton)}>
          <Button onClick={onContinue} text={"Continue"} />
        </div>
      </div>
    </div>
  );
};
