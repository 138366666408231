export default [
  {
    id: "support",
    message:
      "I have a number of resources that can help you get immediate support!",
    trigger: "support-q1",
  },
  {
    id: "support-q1",
    message: "What type of support do you need?",
    trigger: "support-options",
  },
  {
    id: "support-options",
    options: [
      {
        value: 1,
        label: "Provider Search",
        trigger: "support-providersearch",
      },
      {
        value: 2,
        label: "Digital authorization",
        trigger: "support-digitalauth",
      },
      {
        value: 3,
        label: "Contact wellbeing specialist",
        trigger: "support-specialist",
      },
      {
        value: 4,
        label: "Sign up for free coaching",
        trigger: "support-coaching",
      },
    ],
  },
  {
    id: "support-providersearch",
    message:
      "I see you're interested in learning more about your provider options.",
    trigger: "general-response-1",
    //Negative -Findhelp.org
    //Postive - no card
  },
  {
    id: "support-digitalauth",
    message: "I see you're interested in obtaining a digital auth",
    trigger: "general-response-1",
    //Postive - no card
    //Negative - no card
  },
  {
    id: "support-specialist",
    message: "I see you're interested in contacting a wellbeing specialist",
    trigger: "general-response-1",
  },
  {
    id: "support-coaching",
    message: "I see you're interested in signing up for a free coaching",
    trigger: "general-response-1",
  },
  {
    id: "support-providersearch-no",
    message: "That’s okay! I will now connect you to the provider directory!",
    trigger: "general-no",
    //Premium provider finder(Alma) - SF Site
    //Provider Search and EWS contact Card - Docx
  },
  {
    id: "support-digitalauth-no",
    message: "That’s okay! I will now send you to the digital auth platform",
    trigger: "general-no",
    //EWS Digital Authorization
  },
  {
    id: "support-specialist-no",
    message: "That's okay",
    trigger: "general-no",
  },
  {
    id: "support-coaching-no",
    message: "That's okay",
    trigger: "general-no",
  },
];
