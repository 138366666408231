export default [
  {
    id: "lifestyle",
    message:
      "I have a number of resources that can help you improve your daily lifestyle!",
    trigger: "lifestyle-q1",
  },
  {
    id: "lifestyle-q1",
    message: "Do you have any areas you want to focus on or particular goals?",
    trigger: "lifestyle-options",
  },
  {
    id: "lifestyle-options",
    options: [
      {
        value: 1,
        label: "Reduce drinking and/or drug use",
        trigger: "lifestyle-reduce",
      },
      { value: 2, label: "Sleep better", trigger: "lifestyle-sleep" },
      {
        value: 3,
        label: "Have more energy/focus",
        trigger: "lifestyle-energy",
      },
      { value: 4, label: "Work life balance", trigger: "lifestyle-balance" },
      { value: 4, label: "Parenting", trigger: "lifestyle-parenting" },
      {
        value: 5,
        label: "I'm not sure or none of these",
        trigger: "lifestyle-none",
      },
    ],
  },
  {
    id: "lifestyle-reduce",
    message:
      "Changing your relationship with drinking and/or drugs can be a great first step in your wellness journey!",
    trigger: "general-question-1",
    //Negative -
    //Postive -
  },
  {
    id: "lifestyle-sleep",
    message:
      "Changing your can be a great first step in your wellness journey!",
    trigger: "",
  },
  {
    id: "lifestyle-energy",
    message:
      "Changing your can be a great first step in your wellness journey!",
    trigger: "",
  },
  {
    id: "lifestyle-balance",
    message:
      "Changing your can be a great first step in your wellness journey!",
    trigger: "",
  },
  {
    id: "lifestyle-parenting",
    message:
      "Changing your can be a great first step in your wellness journey!",
    trigger: "",
  },
  {
    id: "lifestyle-none",
    message:
      "Changing your can be a great first step in your wellness journey!",
    trigger: "",
  },
];
