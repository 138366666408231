import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue, onClose }) => {
  return (
    <div className={cn(style.s4)}>
      <div className={cn(style.resultContainer)}>
        <div>
          <b>No-cost counseling is authorized for:</b>
        </div>
        <div className={cn(style.title)}>Janet Wood</div>
        <div>
          Not the right person? Call{" "}
          <a href="tel:15555555555">1-555-555-5555</a>
        </div>
        <div className={cn(style.downloadButton)}>
          <Button>
            <Icon icon="document" />
            Download confirmation letter
          </Button>
        </div>

        <div className={cn(style.bulletItems)}>
          <div className={cn(style.bulletItem)}>
            <Icon icon="checkmark" />
            <span>Authorization code: STK421-01</span>
          </div>
          <div className={cn(style.bulletItem)}>
            <Icon icon="checkmark" />
            <span>Amount: 3 no-cost visits</span>
          </div>
          <div className={cn(style.bulletItem)}>
            <Icon icon="checkmark" />
            <span>Valid thru: 12/31/2023</span>
          </div>
        </div>

        <hr className={cn(style.divider)} />

        <div className={cn(style.title)}>Next steps:</div>
        <div className={cn(style.stepDescription)}>
          <p>
            <b>1. Download your confirmation letter to give to your provider</b>
            <br />
            Tell your provider you want to use your EAP benefits. The
            confirmation letter has steps to help them submit your claim.
          </p>
          <p>
            <b>2. Find an in-network provider with EAP services</b>
            <br />
            Need to find a behavioral health provider? Or want to check if your
            provider is in network? Search our directory and filter for EAP
            services.
          </p>
        </div>
        <div className={cn(style.findProviderButtonContainer)}>
          <Button>
            Find a provider <Icon icon="extWebsite" />
          </Button>
        </div>
        <div className={cn(style.stepButtonContainer)}>
          <div className={cn(style.closeButton)} onClick={onClose}>
            <span>Close</span>
          </div>
        </div>
        <hr className={cn(style.divider)} />

        <div >
          Payment ror provider services is subject to eligibility for tal
          services at the time services are provided. benefit plan limitations,
          availability of remaining coverage, and any limitations imposed by
          regulatory requirements.
        </div>
      </div>
    </div>
  );
};
