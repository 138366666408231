import optumLogo from "assets/images/Optum_Logo_ora_RGB.png";
import tmobileLogo from "assets/demo_brands/tmobile.jpg";
import nikeLogo from "assets/demo_brands/nike.jpg";
import target from "assets/demo_brands/target.png";

export default {
    "default": {
        "logo": optumLogo,
        vars: {
            "--logo-height": "60px",
        }
    },
    "nike": {
        "logo": nikeLogo,
        vars: {
            "--logo-height": "120px",
            "--palette-primary": "#000000",
            "--palette-primary-hover": "#000000",
            "--palette-button-border" :"#000000",         
            "--palette-button-bg" :"#000000",
            "--palette-button-bg-hover" :"#303030",
            "--palette-secondary": "#FFFFFF",
            "--palette-warm-white": "#FFFFFF"
        }
    },
    "tmobile": {
        "logo": tmobileLogo,
        vars: {
            "--logo-height": "60px",
            "--palette-primary": "#E20074",
            "--palette-primary-hover": "#E20074",
            "--palette-button-border" :"#000000",         
            "--palette-button-bg" :"#E20074",
            "--palette-button-bg-hover" :"#E20074",
            "--palette-secondary": "#000000",
            "--palette-warm-white": "#FFFFFF"
        }
    },
    "target": {
        "logo": target,
        vars: {
            "--logo-height": "120px",
            "--palette-primary": "#CC0000",
            "--palette-primary-hover": "#DD0000",
            "--palette-button-border" :"#000000",         
            "--palette-button-bg" :"#CC0000",
            "--palette-button-bg-hover" :"#DD0000",
            "--palette-secondary": "#FFFFFF",
            "--palette-warm-white": "#FFFFFF"
        }
    }
}