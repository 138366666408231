import React, { useContext, useEffect } from "react";
import cn from "classnames";
import { useParams } from "react-router-dom";

// styles
import style from "./style.scss";
import companyLogo from "assets/images/company-logo.png";
import optumLogo from "assets/images/Optum_Logo_ora_RGB.png";
import Image from "atom/Image";
import { ThemeContext } from "provider/ThemeProvider";

export default ({ containerStyle, boxStyle, children, isFooter = true }) => {
  const { data, setData, logo } = useContext(ThemeContext);
  const { brand } = useParams();

  useEffect(() => {
    setData({ content: { brand } });

    return () => {
      setData({ content: { brand: "default" } });
    };
  }, [brand]);

  return (
    <div className={cn([style.login_wrapper, containerStyle])}>
      <div className={cn([style.login_box, boxStyle])}>
        <div className={cn(style.logo)}>
          <Image src={logo} />
        </div>
        {children}
      </div>
    </div>
  );
};
