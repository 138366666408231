export default [
  {
    id: "administrative",
    message:
      "I have a number of resources that can help you with administrative issues!",
    trigger: "administrative-q1",
  },
  {
    id: "administrative-q1",
    message: "What type of support do you need?",
    trigger: "administrative-options",
  },
  {
    id: "administrative-options",
    options: [
      { value: 1, label: "Legal Guidance", trigger: "administrative-legal" },
      {
        value: 2,
        label: "Financial Coaching",
        trigger: "administrative-financial",
      },
      {
        value: 3,
        label: "Learn about my benefits",
        trigger: "administrative-benefits",
      },
      {
        value: 4,
        label: "I'm not sure or none of these",
        trigger: "administrative-none",
      },
    ],
  },
  {
    id: "administrative-legal",
    message:
      "I see you're in need of some legal assistance and I've got the resources to help!",
    trigger: "administrative-legal-2",
  },
  {
    id: "administrative-legal-2",
    message:
      "People report that they feel additional stress when addressing a legal issue",
    trigger: "general-response-1",
  },
  {
    id: "administrative-financial",
    message:
      "I see you're in need of some financial assistance and I've got the resources to help!",
    trigger: "administrative-financial-2",
  },
  {
    id: "administrative-financial-2",
    message:
      "People report that they feel additional stress when addressing a financial issue",
    trigger: "general-response-1",
    //Negative -AbleTo, Findhelp.org,MSA Coaching
    //Postive -
  },
  {
    id: "administrative-benefits",
    message: "Learn",
    trigger: "general-response-1",
    //Negative -
    //Postive -
  },
  {
    id: "administrative-none",
    message: "I see",
    trigger: "general-response-1",
    //Negative -
    //Postive -
  },
  {
    id: "administrative-legal-no",
    message:
      "That’s okay! I can still provide access to our most commonly used legal services available to you!",
    trigger: "general-no",
  },
  {
    id: "administrative-financial-no",
    message:
      "That's okay! I can still recommend some excellent financial coaching applications.",
    trigger: "general-no",
    // Applications bubble chats not cards
  },
  {
    id: "administrative-benefits-no",
    message: "That's okay",
    trigger: "general-no",
    //
  },
  {
    id: "administrative-none-no",
    message: "That's okay",
    trigger: "general-no",
    //
  },
];
