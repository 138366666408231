import React, { useState } from "react";
import cn from "classnames";
// styles
import style from "./style.scss";

export default () => {
  return (
    <div className={cn(style.question_wrapper)}>
      <div className={cn(style.question_items)}>
        <Expandable
          question={"What is a nickname?"}
          answer={
            "A nickname is a unique and secure anonymous username that you create for Optum's Support Finder. You can either choose your own nickname or have one auto generated."
          }
        />
        <Expandable
          question={"What if I forgot my nickname?"}
          answer={
            "If you forget your nickname, you will need to create a new one. This is important for maintaining your privacy and security."
          }
        />
        <Expandable
          question={"Is my information kept private?"}
          answer={
            "Yes, Support Finder maintains anonymity and does not capture or store personal member information. No data is shared with third parties for marketing or advertising purposes."
          }
        />
      </div>
    </div>
  );
};

export const Expandable = ({ question, answer }) => {
  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(prev => !prev);
  };
  return (
    <div className={cn(style.question_item_wrapper)}>
      <div
        className={cn(style.question_item, expand ? style.question_expand : "")}
        onClick={handleExpand}
      >
        {/* <span className={style.svgWrapper}>
          {React.createElement(iconEnum["arrowsRight"])}
        </span> */}
        {question}
        <span className={style.plus}>{expand ? "-" : "+"}</span>
      </div>
      <div className={cn(style.answer_item, expand ? style.answer_expand : "")}>
        {answer}
      </div>
    </div>
  );
};
