import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue, onBack }) => {
  return (
    <div className={cn(style.s3)}>
      <div className={cn(style.formContainer)}>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formTitle)}>Your information</div>
          <div className={cn(style.review)}>
            {/* edit */}
            <div className={cn(style.reviewEditButton)}>Edit</div>

            {/* content */}
            <div className={cn(style.reviewItem)}>
              <div className={cn(style.label)}>Name & Address</div>
              <div className={cn(style.value)}>
                Janet Wood <br />
                2532 Main St <br />
                Boulder, CO 90215 <br />
              </div>
            </div>

            <div className={cn(style.reviewItem)}>
              <div className={cn(style.label)}>Date of birth</div>
              <div className={cn(style.value)}>
                02/25/1986
              </div>
            </div>

            <div className={cn(style.reviewItem)}>
              <div className={cn(style.label)}>Email address</div>
              <div className={cn(style.value)}>JanWood@gmail.com</div>
            </div>

            <div className={cn(style.reviewItem)}>
              <div className={cn(style.label)}>
                Relationship to the policy holder
              </div>
              <div className={cn(style.value)}>Self</div>
            </div>
          </div>
        </div>
      </div>

      <div className={cn(style.stepButtonContainer)}>
        <div className={cn(style.backButton)} onClick={onBack}>
          <span>Back</span>
        </div>
        <div className={cn(style.continueButton)}>
          <Button onClick={onContinue} text={"Looks Great"} />
        </div>
      </div>
    </div>
  );
};
