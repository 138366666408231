import Cookies from "universal-cookie";

const NICKNAME_KEY = "OPTUMDEMO_NICKNAME";
const nicknameCookie = new Cookies();

const NicknameProvider = {
  getNickname: () => {
    return nicknameCookie.get(NICKNAME_KEY, { path: "/" });
  },
  setNickname: nickname => {
    nicknameCookie.set(NICKNAME_KEY, nickname, { path: "/" });
  },
  removeNickname: () => {
    nicknameCookie.remove(NICKNAME_KEY, { path: "/" });
  },
};

export default NicknameProvider;
