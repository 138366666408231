import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Icon from "atom/Icon";
import style from "./style.scss";
import cn from "classnames";
import { Heading } from "atom/Typography";
import PageCenter from "atom/PageCenter";

const questions = [
  {
    question: "Are my conversations and activity confidential?",
    answer:
      "Yes, Support Finder maintains anonymity and does not capture or store personal member information. No data is shared with third parties for marketing or advertising purposes.",
  },
  {
    question: "How do I speak with a person/counselor immediately?",
    answer:
      "There are multiple ways to contact your Emotional Wellbeing Specialist throughout the application. The first area is located at the top of the page or click here.",
  },
  {
    question: "Can I share this site with family members?",
    answer: `Yes, the benefits of Support Finder can be shared with your family and caregivers. Simply go to settings under your profile avatar, find the "Share link" section, and click 'copy'. You're now ready to distribute this link as needed.`,
  },
  {
    question: "How do I change my language preference?",
    answer: `Simply go to settings under your profile avatar, find the "Your Language" section. Use the dropdown to select/update your language preference.`,
  },
  {
    question: "How do I change my current location?",
    answer: `Simply go to settings under your profile avatar, find the "Your Region" section. Use the dropdown to select/update your regional preference. Please confirm your location using the location dropdown before contacting EAP. Some countries/regions calls originating from mobile phones, may incur a toll cost to the caller.`,
  },
];

export default ({}) => {
  const [active, setActive] = useState();

  function handleClick(e) {
    if (active == e) setActive();
    else setActive(e);
  }

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <PageCenter
      id={"faqs"}
      rootClass={cn(style.root)}
      contentClass={cn(style.content)}
    >
      <Heading type="h2" centered>
        Frequently Asked Questions
      </Heading>
      <div className={cn(style.questions)}>
        {questions.map(({ question, answer }, i) => (
          <motion.div
            ref={ref}
            key={i}
            variants={{
              visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
              hidden: { opacity: 0, y: i * 15 },
            }}
            initial="hidden"
            animate={control}
            className={cn(style.item)}
          >
            <div
              className={cn(style.collapse, active == i + 1 && style.topactive)}
              onClick={() => handleClick(i + 1)}
            >
              {question} <Icon icon={"cardarrow"} />
            </div>
            <div
              className={cn(style.collapsible, active == i + 1 && style.active)}
            >
              {answer}
            </div>
          </motion.div>
        ))}
      </div>
    </PageCenter>
  );
};
