import React from "react";
import { Link } from "react-router-dom";
import RightArrow from "assets/icons/right-arrow.svg";
import style from "./style.scss";
import cn from "classnames";
import { getLinktype, LINKTYPES } from "lib/utils";

export const LinkButton = props => {
  let {
    to,
    TagName = "",
    className,
    children,
    label,
    id,
    withArrow,
    target,
    ...rest
  } = props;

  if (getLinktype(to) === LINKTYPES.ABSOLUTE);
  {
    target = "_blank";
  }

  if (TagName) {
    return (
      <TagName
        target={target}
        {...rest}
        className={cn(style.anchor, style.linkButton, className)}
      >
        {children || label} {withArrow && <RightArrow />}
      </TagName>
    );
  } else {
    return (
      <Link
        {...{ to, id, target, ...rest }}
        className={cn(style.anchor, className)}
      >
        {children || label}
        {withArrow && <RightArrow />}
      </Link>
    );
  }
};

export default ({
  className,
  href = "#",
  target = "_blank",
  label,
  download,
  children,
  withArrow,
  ...rest
}) => (
  <a
    className={cn(style.anchor, className)}
    target={href !== "#" ? target : null}
    {...{ href, download }}
    {...rest}
  >
    {children || label}
    {withArrow && <RightArrow />}
  </a>
);
