import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import { Heading } from "atom/Typography";
import PageCenter from "atom/PageCenter";

const ctas = [
  { title: "Call", subtitle: "Speak to a trained specialist" },
  { title: "Schedule", subtitle: "Self schedule an online/virtual session" },
  { title: "Chat", subtitle: "Chat with a trained specialist" },
];

export default ({ call, chat }) => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  return (
    <PageCenter id={"ctas"} contentClass={cn(style.content)}>
      {ctas.map(({ title, subtitle }, i) => (
        <motion.div
          ref={ref}
          key={i}
          variants={{
            visible: { opacity: 1, y: 0, transition: { duration: 1 } },
            hidden: { opacity: 0, y: 50 + i * 50 },
          }}
          initial="hidden"
          animate={control}
          className={cn(style.item)}
          onClick={() =>
            title == "Call" ? call() : (title = "Chat" ? chat() : null)
          }
        >
          <div>
            <>
              <Heading type="h3">{title}</Heading>
              <p>{subtitle}</p>
            </>
            <Icon icon={"cardarrow"} />
          </div>
        </motion.div>
      ))}
    </PageCenter>
  );
};
