import React from "react";
import SVG from "react-inlinesvg";

export default ({src, ...props}) => {
    let ext
    if (typeof src === 'string') {
        ext = src.split(".").pop().split("?").shift();
        if (ext.toLowerCase() === "svg" ) {
            return <SVG {...props} />
        }
    } else if (typeof src === "object") {
        const svgIcon = src
        return svgIcon
    }

    return <img src={src} {...props} />
}