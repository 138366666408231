import React, { createContext, useState, useEffect } from "react";
export const UIStatusContext = createContext(null);

export const UIStatusProvider = ({ children }) => {
  // const [pageTransitionStatus, setPageTransitionStatus] = useState('entered') // entered, exited
  const [modalLayer, setModalLayer] = useState(0);

  return (
    <UIStatusContext.Provider
      value={{
        modalLayer,
        setModalLayer,
      }}
    >
      {children}
    </UIStatusContext.Provider>
  );
};
