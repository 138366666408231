import arrowsRotateSolid from "assets/icons/arrows-rotate-solid.svg";
import arrowsRight from "assets/icons/arrows-right.svg";
import error from "assets/icons/error.svg";
import loginAvatar from "assets/icons/login-avatar.svg";
import circleXMark from "assets/icons/circle-xmark-regular.svg";
import search from "assets/icons/search.svg";
import convo from "assets/icons/convo.svg";
import phone from "assets/icons/phone.svg";
import avatar from "assets/icons/avatar.svg";
import book from "assets/icons/book.svg";
import brain from "assets/icons/brain.svg";
import highfive from "assets/icons/highfive.svg";
import moon from "assets/icons/moon.svg";
import pet from "assets/icons/pet.svg";
import piggybank from "assets/icons/piggybank.svg";
import wellness from "assets/icons/wellness.svg";
import info from "assets/icons/info.svg";
import bookmark from "assets/icons/bookmark.svg";
import cardarrow from "assets/icons/card-arrow.svg";
import contact from "assets/icons/contact.svg";
import copy from "assets/icons/copy.svg";
import stethoscope from "assets/icons/stethoscope.svg";
import gears from "assets/icons/gears.svg";
import chat from "assets/icons/chat.svg";
import care from "assets/icons/care.svg";
import collab from "assets/icons/collab.svg";
import handshake from "assets/icons/handshake.svg";
import heart from "assets/icons/heart.svg";
import openlink from "assets/icons/openlink.svg";
import options from "assets/icons/options.svg";
import magnifyingGlass from "assets/icons/magnifying-glass.svg";
import pause from "assets/icons/pause.svg";
import extWebsite from "assets/icons/extwebsite.svg";
import checkmark from "assets/icons/checkmark.svg";
import document from "assets/icons/document.svg";

export default {
  arrowsRotateSolid,
  arrowsRight,
  error,
  loginAvatar,
  circleXMark,
  search,
  convo,
  phone,
  avatar,
  book,
  brain,
  highfive,
  moon,
  pet,
  piggybank,
  wellness,
  info,
  bookmark,
  cardarrow,
  contact,
  copy,
  stethoscope,
  gears,
  chat,
  care,
  collab,
  handshake,
  heart,
  openlink,
  options,
  magnifyingGlass,
  pause,
  extWebsite,
  checkmark,
  document
};
