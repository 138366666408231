import React, { useRef, useState } from "react";
import InstructionImage1 from "assets/images/tutorial1_icon1.svg";
import InstructionImage2 from "assets/images/help.svg";
import InstructionImage3 from "assets/images/house.svg";
import Carousel from "atom/Carousel";
import Modal from "atom/Modal";
import style from "./style.scss";
import cn from "classnames";

export default ({ ariaLabelPause, onClose, openChat }) => {
  const slider = useRef(null);
  const [sliderPaused, setSliderPaused] = useState(false);

  return (
    <Modal
      id={"tutorial"}
      contentClass={style.modal}
      onClose={onClose}
      isCloseButton={false}
    >
      <Carousel
        className={cn(style.banner)}
        ref={slider}
        paused={sliderPaused}
        focused={false}
        onPaused={setSliderPaused}
        pauseAria={ariaLabelPause}
        settings={{
          // same as dots
          infinite: false,
          customPaging: _i => <div style={{ height: 10, width: 30 }} />,
          speed: 250,
          autoplay: false,
        }}
      >
        <div>
          <div className={style.tutorial_item_container}>
            <div className={style.tutorial_item}>
              <Tu1 />
            </div>
          </div>
        </div>
        <div>
          <div className={style.tutorial_item_container}>
            <div className={style.tutorial_item}>
              <Tu2 />
            </div>
          </div>
        </div>
        <div>
          <div className={style.tutorial_item_container}>
            <div className={style.tutorial_item}>
              <Tu3 onClick={openChat} />
            </div>
          </div>
        </div>
      </Carousel>
    </Modal>
  );
};

// ============================== steps: ==============================

const Tu1 = () => {
  return (
    <div className={cn(style.tu_container, style.tu1)}>
      <div className={style.title}>
        Get to know Emotional Wellbeing Solutions
      </div>
      <div style={{ marginBottom: "20px", marginTop: "10px" }}>
        <InstructionImage1 style={{ height: 145, maxWidth: 258 }} />
      </div>

      <div className={style.text}>
        <p>
          Optum Emotional Wellbeing Solutions is a modern and flexible employee
          assistance program (EAP) that offers support for everyday life. It’s a
          wide range of benefits for you and your family to support your
          everyday life and wellbeing. You get services like:
        </p>
        <ul>
          <li>No-cost counseling</li>
          <li>24/7 in-the-moment support</li>
          <li>Self-help programs</li>
          <li>Financial and legal guidance</li>
          <li>Educational resources</li>
        </ul>
        <p>
          It’s covered by your employer, so there’s no cost to you. And it’s
          entirely confidential.
        </p>
      </div>
    </div>
  );
};

const Tu2 = () => {
  return (
    <div className={style.tu_container}>
      <div className={style.title}>
        Personalized care options with Support Finder
      </div>
      <InstructionImage2 style={{ height: 181, maxWidth: 358 }} />
      <div className={style.text}>
        <p>
          This anonymous and easy-to-use tool can help you find the emotional
          wellbeing services that are right for you. Simply chat with a
          sophisticated, virtual guide and get personalized recommendations.
        </p>
      </div>
    </div>
  );
};

const Tu3 = ({ onClick }) => {
  return (
    <div className={style.tu_container}>
      <div className={style.title}>How does Support Finder work?</div>
      <InstructionImage3 style={{ height: 191, maxWidth: 356 }} />
      <div className={style.text}>
        <p>
          You can jump in and start chatting with our virtual guide now. You’ll
          answer some questions and have a conversation about your goals and how
          you’re feeling. <br />
          <br />
          When you’re done, you’ll get clinically-based recommendations for
          resources to feel better. Come back any time and you can pick up where
          you left off.
        </p>
      </div>
      <div className={style.button_container}>
        <button onClick={onClick} className={style.button}>
          Start chatting
        </button>
      </div>
    </div>
  );
};
