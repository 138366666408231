import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import IconClose from "assets/icons/circle-xmark-regular.svg";
import style from "./style.scss";
import cn from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export default ({
  onClose,
  withClose = true,
  closeCustom,
  closeClass,
  children,
  className,
  clickOutside = false,
  isCloseButton = true,
  withSlide = false,
  containerClass,
  contentClass,
  id,
}) => {
  // function disableScroll() {
  //   document.documentElement.style.setProperty("scroll-behavior", "unset");
  //   const scrollTop = window.scrollY || document.documentElement.scrollTop;
  //   const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

  //   window.onscroll = function() {
  //     window.scrollTo(scrollLeft, scrollTop);
  //   };
  // }

  // function enableScroll() {
  //   window.onscroll = function() {};
  // }

  // const handleWindowWheel = e => {
  //   if (localStorage.getItem("demo_openedmodal")) {
  //     e.preventDefault();
  //   }
  // };

  useEffect(() => {
    const withModal = localStorage.getItem("demo_openedmodal");
    if (!withModal) {
      localStorage.setItem("demo_openedmodal", JSON.stringify(id));
    }
    // window.addEventListener("wheel", handleWindowWheel, { passive: false });
    disableBodyScroll(document);
    // disableScroll();
  }, []);

  useEffect(() => {
    return () => {
      const withModal = localStorage.getItem("demo_openedmodal");

      if (JSON.parse(withModal) == id) {
        localStorage.removeItem("demo_openedmodal");
        enableBodyScroll(document);
        document.documentElement.style.setProperty("scroll-behavior", "smooth");
        // window.removeEventListener("wheel", handleWindowWheel);
        // enableScroll();
      }
      // onClose();
    };
  }, []);

  return (
    <div className={cn(style.modal, className)}>
      <div
        className={cn(style.space)}
        onClick={() => (clickOutside ? onClose() : null)}
      />
      <motion.div
        initial={{ translateY: withSlide ? 500 : 0 }}
        animate={{ translateY: 0 }}
        transition={{ ease: "easeInOut", duration: 0.5 }}
        className={cn(style.content_container, containerClass)}
      >
        <div className={cn(style.content, contentClass)}>
          {withClose ? (
            <div
              className={cn(
                style.close,
                !isCloseButton && style.mobile_close,
                closeClass
              )}
              onClick={() => onClose()}
            >
              {closeCustom ? closeCustom : <IconClose />}
            </div>
          ) : null}
          {children}
        </div>
      </motion.div>
    </div>
  );
};
