import React, { useState, useRef, useEffect } from "react";
import { LinkButton } from "atom/Anchor";
import style from "./style.scss";
import cn from "classnames";

const BannerContent = ({
  text,
  name,
  buttonText,
  isVisible,
  target,
  onFocus,
  onBlur,
  className,
  isActive,
  onClick,
  ...rest
}) => {
  return (
    <div
      className={cn(className, style.content, isActive ? style.active : "")}
      {...rest}
    >
      <div
        className={cn(style.main)}
        tabIndex={0}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <div
          className={cn(style.text)}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {target && target != "undefined" && target?.trim() != "" ? (
          <LinkButton
            className={cn(style.button)}
            tabIndex={0}
            to={target}
            label={`${buttonText} >`}
            aria-label={`${buttonText}. ${text}`}
          />
        ) : null}

        {typeof onClick === "function" ? (
          <div
            className={cn(style.button)}
            tabIndex={0}
            onClick={onClick}
          >
            {`${buttonText} >`}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default BannerContent;
