export default {
  calm: {
    category: ["Self-Care"],
    title: "Calm",
    description:
      "Self-Care evidence based mental health tool and Daily mindfulness sessions  and mindfulness content",
    link: "https://www.calm.com/b2b/ews/subscribe?code=optum",
  },
  findHelp: {
    category: ["Self-Care"],
    title: "Findhelp.org",
    description: "Aggregating website to guide users to emergency services",
    link: "https://www.findhelp.org/",
  },
  talkspace: {
    category: ["Therapy"],
    title: "Talkspace",
    description: "Virtual 1:1 Therapy sessions",
    link: "",
  },
  financial: {
    category: ["Financial and Legal"],
    title: "Financial Wellbeing powered by BrightPlan",
    description:
      "Articles, calculators and content to help support Financial Wellbeing, or schedule a call with a Financial Coach",
    link: "",
  },
  financialInt: {
    category: ["Financial and Legal"],
    title: "Financial Wellbeing powered by BrightPlan",
    description:
      "Articles, calculators and content to help support Financial Wellbeing",
    link: "",
  },
  legal: {
    category: ["Financial and Legal"],
    title: "Legal CLC",
    description: "Legal support US CLC",
    link:
      "https://www.clcmembers.net/launch/6d700460-6820-11e8-9ef8-1428ea656a80?company_code=Textron",
  },
  ableTo: {
    category: ["Coaching"],
    title: "AbleTo",
    description: "Virtual coaching",
    link: "https://www.ableto.com/",
  },
  childSupport: {
    category: ["Coaching"],
    title: "Child Caregiver Support",
    description: "Neurodiverse caretaker/parenting support",
    link: "",
  },
  professionalResilience: {
    category: ["Coaching"],
    title: "Professional Resilience",
    description: "Support for Neurodiverse employees and managers",
    link: "",
  },
  personalWellbeing: {
    category: ["Self-Care", "Digital"],
    title: "Personal Wellbeing",
    description: "On demand content for managing Anxiety, stress, and more",
    link: "",
  },
  providerSearch: {
    category: ["Therapy"],
    title: "Provider Search",
    description: "Access to Optum provider directory",
    link:
      "https://obh.werally.com/behavioralProvider/root?adobe_mc=MCMID%3D7C81214543227484811751830585352877656614%7CTS%3D1687966976",
  },
  premiumNetwork: {
    category: ["Therapy"],
    title: "Premium Network",
    description: "Access a provider in 2-5 days",
    link: "",
  },
  peerToPeer: {
    category: ["Self-Care", "Coaching"],
    title: "Peer to peer Support",
    description:
      "24/7 anonymous live chat support with your peers to discuss loneliness, stress, relationship issues and more",
    link: "",
  },
  mindfi: {
    category: ["Therapy", "Coaching"],
    title: "MindFi Virtual Coaching and Therapy",
    description: "Schedule Virtual Coaching or Therapy sessions ",
    link: "",
  },
};
