import React from "react";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";
import Icon from "atom/Icon";
import Button from "atom/Button";

const items = [
  {
    icon: "brain",
    title: "Get answers and find help quickly",
    subtext:
      "Share your goals and challenges, and we’ll provide your covered options.",
    link: "fullchat",
  },
  {
    icon: "chat",
    title: "Mental Wellbeing",
    subtext:
      "You deserve to feel better. We can help you find the right resources.",
    link: "pathways",
  },
];

export default ({ onClick }) => (
  <div className={cn(style.chatbotHome)}>
    <Heading type={"h3"}>Start a new journey</Heading>
    <p>
      Support Finder is a globally accessible interactive platform, providing
      anonymous guidance to users regarding Optum’s emotional wellbeing
      benefits.
    </p>
    <div className={cn(style.option)}>
      {items.map(({ icon, title, subtext, link }, i) => (
        <div key={i} className={cn(style.item)}>
          <div className={cn(style.text)}>
            <Heading type={"h4"}>{title}</Heading>
            <p>{subtext}</p>
            <Button light text="Explore" onClick={() => onClick(link)} />
          </div>
          <Icon icon={icon} />
        </div>
      ))}
    </div>
  </div>
);
