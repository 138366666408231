import React, { useRef, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Slider from "react-slick";
import optumLogo from "assets/images/Optum_Logo_ora_RGB.png";
import Button from "atom/Button";
import Modal from "atom/Modal";
import SelectCountry from "atom/SelectCountry";
import SelectLanguage from "atom/SelectLanguage";
import { Expandable } from "molecule/LoginQuestions";
import NicknameProvider from "provider/NicknameProvider";
import SettingsProvider from "provider/SettingsProvider";
import avatarEnum from "assets/avatars";
import style from "./style.scss";
import cn from "classnames";

const avatars = [
  { text: "Your avatar:", keyw: "user" },
  { text: "Your chat avatar:", keyw: "chat" },
];

const SelectAvatar = ({ keyw, text, selected, onClick }) => {
  var sett = {
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 4,
    autoplay: false,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const n = 18;

  return (
    <div className={cn(style.avatar)}>
      {text}
      <Slider
        {...sett}
        initialSlide={(selected && selected.match(/\d+/)[0]) || 1}
      >
        {[...Array(n)].map((_e, i) => {
          const finalKey = keyw + (i + 1);
          return (
            <div
              key={i}
              className={cn(
                style.item,
                selected && selected == finalKey && style.selected
              )}
              onClick={() => onClick(finalKey, keyw)}
            >
              <img src={avatarEnum[finalKey]} />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ({ onClose = () => {} }) => {
  const nickname = NicknameProvider.getNickname();
  const countrylang = SettingsProvider.getCountryLanguage();
  const currentAvatars = SettingsProvider.getAvatars();
  const [selectedCountrylang, setSelectedCountrylang] = useState([]);
  const [selectedAvatars, setSelectedAvatars] = useState([]);

  useEffect(() => {
    setSelectedCountrylang(countrylang);
    setSelectedAvatars(currentAvatars);
  }, []);

  const handleSubmit = e => {
    SettingsProvider.setCountryLanguage(selectedCountrylang);
    SettingsProvider.setAvatars(selectedAvatars);
    onClose();
  };

  const handleChange = (e, name) => {
    setSelectedCountrylang(prevState => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const handleChangeAvatars = (e, name) => {
    setSelectedAvatars(prevState => ({ ...prevState, [name]: e }));
  };

  return (
    <Modal id={"settings"} contentClass={cn(style.modal)} onClose={onClose}>
      <img className={cn(style.logo)} src={optumLogo} />
      <div className={cn(style.intro)}>
        <h4>Your Profile </h4>
        <p>
          <strong>Welcome {nickname}! </strong>
          <br />
          Please feel free to update your settings as you like.
        </p>
      </div>
      <div className={cn(style.avatars)}>
        {avatars.map((value, i) => (
          <SelectAvatar
            {...value}
            key={i}
            onClick={handleChangeAvatars}
            selected={selectedAvatars[value.keyw]}
          />
        ))}
      </div>
      <div className={cn(style.preferences)}>
        <div className={cn(style.select)}>
          <Form.Label htmlFor="language">Language preferences:</Form.Label>
          <SelectLanguage
            handleChange={e => handleChange(e, "language")}
            selected={selectedCountrylang["language"]}
          />
        </div>
        <div className={cn(style.select)}>
          <Form.Label htmlFor="location">Location preferences:</Form.Label>
          <SelectCountry
            handleChange={e => handleChange(e, "country")}
            selected={selectedCountrylang["country"]}
          />
        </div>
      </div>
      <div className={cn(style.questions)}>
        <h4>Questions?</h4>
        <div className={cn(style.items)}>
          <Expandable
            question={"Are my settings kept private?"}
            answer={
              "Yes, Support Finder maintains anonymity and does not capture or store personal member information. No data is shared with third parties for marketing or advertising purposes."
            }
          />
          <Expandable
            question={"What if I forgot my nickname?"}
            answer={
              "If you forget your nickname, you will need to create a new one. This is important for maintaining your privacy and security."
            }
          />
        </div>
      </div>
      <Button text="Save" onClick={() => handleSubmit()} />
      <div className={cn(style.cancel)} onClick={onClose}>
        Cancel
      </div>
    </Modal>
  );
};
