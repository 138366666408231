import React, { useState } from "react";
import cn from "classnames";

import Form from "react-bootstrap/Form";

// styles
import style from "./style.scss";

const countries = [
  "Egypt",
  "China",
  "Netherlands",
  "England",
  "United States",
  "France",
  "Canada",
  "Germany",
  "Israel",
  "Italy",
  "Japan",
  "South Korea",
  "Malaysia",
  "Poland",
  "Brazil",
  "Russia",
  "Mexico",
  "Sweden",
  "Thailand",
  "Turkey",
];

export default ({ selected = "", handleChange }) => (
  <Form.Select
    aria-label="Select a Location"
    className={cn(style.root, selected ? style.has_value : style.no_value)}
    value={selected}
    onChange={handleChange}
  >
    <option disabled={true} value="">
      Select your location
    </option>
    {countries
      .sort((a, b) => (a > b ? 1 : -1))
      .map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })}
  </Form.Select>
);
