import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Button from "atom/Button";
import PageCenter from "atom/PageCenter";
import ProgressiveImage from "atom/ProgressiveImage";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const Banner = ({ image, text, children, button }) => {
  const control = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      control.start("visible");
    }
  }, [control, inView]);

  const Text = () => (
    <div className={cn(style.text)}>
      <Heading className={cn(style.heading)}>{text.title}</Heading>
      <p>{text.subtitle}</p>
      {button && <Button onClick={null}>{buttontext}</Button>}
    </div>
  );

  return (
    <PageCenter
      rootClass={cn(style.root)}
      contentClass={cn(style.container, style.full)}
    >
      <motion.img
        ref={ref}
        variants={{
          visible: { scale: 1, transition: { duration: 0.5 } },
          hidden: { scale: 2 },
        }}
        initial="hidden"
        animate={control}
        {...image}
      >
        {/* <ProgressiveImage /> */}
      </motion.img>
      <div className={cn(style.fullText)}>{children || (text && <Text />)}</div>
    </PageCenter>
  );
};

export default Banner;
