import React from "react";
import Call from "assets/images/call.svg";
import Modal from "atom/Modal";
import style from "./style.scss";
import cn from "classnames";
import { Heading } from "atom/Typography";
import Anchor from "atom/Anchor";

export default ({ onClose }) => (
  <Modal
    id={"call"}
    contentClass={style.modal}
    onClose={onClose}
    isCloseButton={true}
  >
    <div className={cn(style.top)}>
      <Call />
    </div>
    <div className={cn(style.text)}>
      <Heading type="h3">Contact us</Heading>
      <div className={cn(style.body)}>
        <div className={cn(style.side)}>
          <Heading type="h5" className={cn(style.title)}>
            Main
          </Heading>
          <Anchor href={"tel:18001027293"}>1800 102 7293</Anchor>
        </div>
        <div className={cn(style.side)}>
          <Heading type="h5" className={cn(style.title)}>
            Alternative
          </Heading>
          <p>
            <Anchor href={"tel:18002098424"}>1800 209 8424</Anchor> Alternative
          </p>
          <p>
            <Anchor href={"tel:+918067649900"}>+91 80 6764 9900</Anchor> Out of
            Country
          </p>
          <p>
            <Anchor href={"tel:+918049269000"}>+91 80 4926 9000</Anchor> Out of
            Country
          </p>
        </div>
      </div>
      <div className={cn(style.note)}>
        <label>NOTE: </label>Please confirm your location using the location
        dropdown at the top of the page before contacting Emotional Wellbeing
        Solutions. Some countries/regions calls originating from mobile phones,
        may incur a toll cost to the caller.
      </div>
    </div>
  </Modal>
);
