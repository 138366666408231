import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import style from "./style.scss";
import cn from "classnames";

export default ({ open, links, other }) => {
  // let location = useLocation().pathname;

  useEffect(() => {
    const e = document.getElementById("side");
    if (open) setTimeout(() => e.focus(), 720);
  }, [open]);

  return (
    <div className={cn(style.sidebar, open ? style.slideIn : style.slideOut)}>
      <a href={"#"} id={"side"} className={cn(style.side)} />
      {links.map(({ label, url, to, target }, i) => (
        <div key={i} className={cn(style.link)}>
          <HashLink to={url}>{label}</HashLink>
        </div>
      ))}
      {other}
    </div>
  );
};
