import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({ text, onClick }) => (
  <div className={cn(style.chip)} onClick={onClick}>
    <div className={cn(style.bg)} />
    {text}
  </div>
);
