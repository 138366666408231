export default [
  {
    id: "wellbeing",
    message:
      "I have a number of resources that can help you improve your overall mental well-being!",
    trigger: "wellbeing-q1",
  },
  {
    id: "wellbeing-q1",
    message: "Do you have any particular goals?",
    trigger: "wellbeing-options",
  },
  {
    id: "wellbeing-options",
    options: [
      { value: 1, label: "Build resilience", trigger: "wellbeing-resilience" },
      {
        value: 2,
        label: "Improve relationships",
        trigger: "wellbeing-relationships",
      },
      {
        value: 3,
        label: "Feel less lonely or isolated",
        trigger: "wellbeing-lonely",
      },
      {
        value: 4,
        label: "Feel happier in general",
        trigger: "wellbeing-happier",
      },
      {
        value: 5,
        label: "Feel less worried or anxious",
        trigger: "wellbeing-worried",
      },
      {
        value: 6,
        label: "I'm not sure or none of these",
        trigger: "wellbeing-notsure",
      },
    ],
  },
  {
    id: "wellbeing-resilience",
    message:
      "I see you're interested in building resilience! This is a great step to improving your mental well-being!",
    trigger: "general-question-1",
  },
  {
    id: "wellbeing-relationships",
    message: "Improving your interpersonal relationships is a fantastic goal!",
    trigger: "general-question-1",
  },
  {
    id: "wellbeing-lonely",
    message:
      "Feeling less lonely is a fantastic goal in terms of improving your mental well-being!",
    trigger: "general-question-1",
  },
  {
    id: "wellbeing-happier",
    message: "Feeling happier is great place to start your well-being journey!",
    trigger: "general-question-1",
  },
  {
    id: "wellbeing-worried",
    message: "Feeling less worried or anxious is a fantastic goal!",
    trigger: "general-question-1",
  },
  {
    id: "wellbeing-notsure",
    message:
      "That's okay! It can be hard to know where to start when beginning your wellness journey!",
    trigger: "general-question-1",
  },
];
