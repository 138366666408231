import React from "react";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import Card from "molecule/Card";
import style from "./style.scss";
import cn from "classnames";
import Button from "atom/Button";

const benefits_cards = [
  {
    image: {
      src: require("/assets/images/card1.png"),
      placeholder: require("/assets/images/card1_thumb.png"),
      height: 300,
      width: 624,
    },
    chip: "Work",
    title: "Uptime",
    content:
      "Self-learning by Uptime Download Uptime and discover thousands of 5 minute Knowledge lessons from thousands of popular books, courses and more. Learn something new in your spare moments, all from a mobile app.",
    button: {
      label: "Download the app for free >",
      link: "https://to.uptime.app/optum-optumgrowth",
    },
  },
  {
    image: {
      src: require("/assets/images/card2.png"),
      placeholder: require("/assets/images/card2_thumb.png"),
      height: 244,
      width: 204,
    },
    chip: "Mental Health",
    title: "AbleTo",
    content:
      "Feel better at your own pace. Self Care by AbleTo offers the latest self-care techniques, coping tools, meditations and more. Get it online or with the app.",
    button: {
      label: "Learn more >",
      link: "https://www.ableto.com/",
    },
  },
  {
    image: {
      src: require("/assets/images/card3.png"),
      placeholder: require("/assets/images/card3_thumb.png"),
      height: 244,
      width: 204,
    },
    chip: "Family & Parenting",
    title: "Rethink",
    content:
      "Rethink care offers virtual consultations with behavior experts which allows you to address issues in a safe space. It also offers resources to cope with issues like developmental and behavior problems faced when raising toddlers and adolescents.",
    button: {
      label: "Learn more >",
      link: "https://www.rethinkcare.com/",
    },
  },
];

export default ({ onClick }) => (
  <PageCenter
    id={"Benefits"}
    rootClass={cn(style.root)}
    contentClass={cn(style.content)}
  >
    <Heading type="h2" centered>
      Your benefits and services
    </Heading>
    <p>
      Below are the benefits and services you are eligible for as part of your
      program.
    </p>
    <div className={cn(style.cards)}>
      <Card {...benefits_cards[0]} className={cn(style.left)} />
      <div className={cn(style.right)}>
        <Card {...benefits_cards[1]} side />
        <Card {...benefits_cards[2]} side />
      </div>
    </div>
    <Button onClick={onClick} text={"View all resources"} />
  </PageCenter>
);
