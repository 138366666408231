import React from "react";
import _ from "lodash";
import cn from "classnames";
import Form from "react-bootstrap/Form";
import iconEnum from "assets/icons";

// styles
import style from "./style.scss";

export default props => {
  const { className, validation, title, ...rest } = props;
  const isError = !_.isEmpty(validation);
  return (
    <div
      title={title}
      className={cn(className, style.input, isError ? style.error : "")}
    >
      <Form.Control {...rest} type="text" />
      {isError &&
        validation.map((a, i) => {
          return (
            <div key={i}>
              {React.createElement(iconEnum["error"])}
              {a}
            </div>
          );
        })}
    </div>
  );
};

export const Select = ({
  ariaLabel,
  selected,
  handleChange,
  optionDisabled,
  items,
  className,
}) => (
  <Form.Select
    aria-label={ariaLabel}
    className={(selected ? cn(style.has_value) : cn(style.no_value), className)}
    value={selected}
    onChange={handleChange}
  >
    <option disabled={true} value="">
      {optionDisabled}
    </option>
    {items.map((item, index) => {
      const { title, value } = item;
      return (
        <option key={index} value={value}>
          {title}
        </option>
      );
    })}
  </Form.Select>
);
