import React from "react";

const EvalBubble = ({ text }) => (
  <div className="eval-root">
    {text}
    <div className="eval-bubble">{text}</div>
  </div>
);

export default [
  {
    id: "eval-1",
    component: (
      <EvalBubble
        text={"Question 1: I've been feeling optimistic about the future"}
      />
    ),
    asMessage: true,
    trigger: "eval-1-options",
  },
  {
    id: "eval-1-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-2" },
      { value: 2, label: "Rarely", trigger: "eval-2" },
      { value: 3, label: "Sometimes", trigger: "eval-2" },
      { value: 4, label: "Often", trigger: "eval-2" },
      { value: 5, label: "All of the time", trigger: "eval-2" },
    ],
  },
  {
    id: "eval-2",
    component: <EvalBubble text={"Question 2: I've been thinking clearly"} />,
    asMessage: true,
    trigger: "eval-2-options",
  },
  {
    id: "eval-2-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-3" },
      { value: 2, label: "Rarely", trigger: "eval-3" },
      { value: 3, label: "Sometimes", trigger: "eval-3" },
      { value: 4, label: "Often", trigger: "eval-3" },
      { value: 5, label: "All of the time", trigger: "eval-3" },
    ],
  },
  {
    id: "eval-3",
    component: (
      <EvalBubble text={"Question 3: I've been dealing with problems well"} />
    ),
    asMessage: true,
    trigger: "eval-3-options",
  },
  {
    id: "eval-3-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-4" },
      { value: 2, label: "Rarely", trigger: "eval-4" },
      { value: 3, label: "Sometimes", trigger: "eval-4" },
      { value: 4, label: "Often", trigger: "eval-4" },
      { value: 5, label: "All of the time", trigger: "eval-4" },
    ],
  },
  {
    id: "eval-4",
    component: (
      <EvalBubble
        text={"Question 4: I've been able to make up my own mind about things"}
      />
    ),
    asMessage: true,
    trigger: "eval-4-options",
  },
  {
    id: "eval-4-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-5" },
      { value: 2, label: "Rarely", trigger: "eval-5" },
      { value: 3, label: "Sometimes", trigger: "eval-5" },
      { value: 4, label: "Often", trigger: "eval-5" },
      { value: 5, label: "All of the time", trigger: "eval-5" },
    ],
  },
  {
    id: "eval-5",
    component: (
      <EvalBubble
        text={"Question 5: I've been feeling close to other people"}
      />
    ),
    asMessage: true,
    trigger: "eval-5-options",
  },
  {
    id: "eval-5-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-6" },
      { value: 2, label: "Rarely", trigger: "eval-6" },
      { value: 3, label: "Sometimes", trigger: "eval-6" },
      { value: 4, label: "Often", trigger: "eval-6" },
      { value: 5, label: "All of the time", trigger: "eval-6" },
    ],
  },
  {
    id: "eval-6",
    component: <EvalBubble text={"Question 6: I've been feeling useful"} />,
    asMessage: true,
    trigger: "eval-6-options",
  },
  {
    id: "eval-6-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-7" },
      { value: 2, label: "Rarely", trigger: "eval-7" },
      { value: 3, label: "Sometimes", trigger: "eval-7" },
      { value: 4, label: "Often", trigger: "eval-7" },
      { value: 5, label: "All of the time", trigger: "eval-7" },
    ],
  },
  {
    id: "eval-7",
    component: (
      <EvalBubble text={"Question 7: I've been feeling calm & relaxed"} />
    ),
    asMessage: true,
    trigger: "eval-7-options",
  },
  {
    id: "eval-7-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-8" },
      { value: 2, label: "Rarely", trigger: "eval-8" },
      { value: 3, label: "Sometimes", trigger: "eval-8" },
      { value: 4, label: "Often", trigger: "eval-8" },
      { value: 5, label: "All of the time", trigger: "eval-8" },
    ],
  },
  {
    id: "eval-8",
    component: <EvalBubble text={"Question 8: I've had energy to spare"} />,
    asMessage: true,
    trigger: "eval-8-options",
  },
  {
    id: "eval-8-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-9" },
      { value: 2, label: "Rarely", trigger: "eval-9" },
      { value: 3, label: "Sometimes", trigger: "eval-9" },
      { value: 4, label: "Often", trigger: "eval-9" },
      { value: 5, label: "All of the time", trigger: "eval-9" },
    ],
  },
  {
    id: "eval-9",
    component: (
      <EvalBubble text={"Question 9: I've been feeling good about myself"} />
    ),
    asMessage: true,
    trigger: "eval-9-options",
  },
  {
    id: "eval-9-options",
    options: [
      { value: 1, label: "Never", trigger: "eval-10" },
      { value: 2, label: "Rarely", trigger: "eval-10" },
      { value: 3, label: "Sometimes", trigger: "eval-10" },
      { value: 4, label: "Often", trigger: "eval-10" },
      { value: 5, label: "All of the time", trigger: "eval-10" },
    ],
  },
  {
    id: "eval-10",
    component: (
      <EvalBubble
        text={
          "Question 10: I've been thinking that I'd be better off dead or of hurting myself in some way"
        }
      />
    ),
    asMessage: true,
    trigger: "eval-10-options",
  },
  {
    id: "eval-10-options",
    options: [
      { value: 1, label: "Yes", trigger: "eval-result" },
      { value: 2, label: "No", trigger: "eval-result" },
    ],
  },
  {
    id: "eval-result",
    message: "Thank you for taking our assessment!",
    trigger: "resources",
  },
];
