export default [
  {
    id: "general-question-1",
    message:
      "May I ask you a series of questions so I can determine which care pathway is best for you?",
    trigger: "general-options",
  },
  {
    id: "general-options",
    options: [
      {
        value: 1,
        label: "Yes",
        trigger: "eval-1",
      },
      {
        value: 2,
        label: "Maybe later",
        trigger: "general-maybe-later",
      },
    ],
  },
  {
    id: "general-response-1",
    message:
      "People often report that they feel additional stress when engaging with the healthcare system",
    trigger: "general-question-2",
  },
  {
    id: "general-question-2",
    message:
      "Would you like some additional resources that can help with stress management?",
    trigger: "general-options",
  },
  {
    id: "general-options-2",
    options: [
      {
        value: 1,
        label: "Yes",
        trigger: "eval-1",
      },
      {
        value: 2,
        label: "No",
        trigger: "general-no",
      },
    ],
  },
  {
    id: "general-question-3",
    message:
      "Would you like to answer a series of questions so my recommendations are more personalized?",
    trigger: "general-options",
  },

  {
    id: "general-maybe-later",
    message:
      "That’s okay you can always come back and take the assessment later.",
    trigger: "general-maybe-later-2",
  },
  {
    id: "general-maybe-later-2",
    message:
      "In the meantime, here are some available care pathways that you can check out!",
    trigger: "resources",
  },
  {
    id: "general-no",
    message: "Have a great rest of your day!",
    trigger: "resources",
  },

  {
    id: "resources",
    message:
      "Please wait while we retrieve the best recommended care pathways based on your feedback.",
    trigger: ({ steps }) => {
      //Evaluation calculator
      function evalTotal(chatSteps) {
        const filteredKeys = Array(9)
          .fill()
          .map((_, i) => `eval-${i + 1}-options`);

        const questionList = Object.fromEntries(
          filteredKeys.map(k => [k, chatSteps[k].value])
        );

        const answerValues = Object.values(questionList).reduce(
          (a, b) => a + b,
          0
        );

        return answerValues;
      }

      if (steps?.wellbeing && steps["wellbeing-options"]) {
        switch (steps["wellbeing-options"].message) {
          case "Build resilience":
            break;
          case "Improve relationships":
            break;
          case "Feel less lonely or isolated":
            break;
          case "Feel happier in general":
            if (steps["general-options"].value == 1) {
              const sum = evalTotal(steps);
              if (sum > 22) return "resources-cards-wellbeing-happier-positive";
              else return "resources-cards-wellbeing-happier-negative";
            } else return "resources-cards-wellbeing-happier-later";
            break;
          case "Feel less worried or anxious":
            break;

          default:
            return "resources-cards-wellbeing";
            break;
        }
      }

      if (steps?.lifestyle) {
        return "resources-cards-lifestyle";
      }

      if (steps?.pathways) {
        if (steps["pathways-options"].value == 1) {
          return "resources-cards-wellbeing-resilience";
        } else if (steps["pathways-options"].value <= 6) {
          return "resources-cards-wellbeing";
        }
        return "resources-cards-lifestyle";
      }

      if (steps?.administrative && steps["administrative-options"]) {
        switch (steps["administrative-options"].message) {
          case "Legal Guidance":
            if (steps["general-options-2"].value == 1) {
              const sum = evalTotal(steps);
              if (sum > 22)
                return "resources-cards-administrative-legal-positive";
              else return "resources-cards-administrative-legal-negative";
            } else return "resources-cards-administrative-legal-no";
            break;
          case "Financial Coaching":
            return "resources-cards-assistance-financial";
            break;
          case "Learn about my benefits":
            break;
          default:
            return "resources-cards-assistance";
            break;
        }
      }

      if (steps?.support) {
        return "resources-cards-support";
      }

      if (steps["not-sure"]) {
        return "resources-cards-notsure";
      }

      return "resource-cards";
    },
  },
];
