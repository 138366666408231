import React, { useState } from "react";
import Button from "atom/Button";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

export default ({ onContinue }) => {
  return (
    <div className={cn(style.s1)}>
      <div className={cn(style.toptext)}>
        Your Employee Assistance Program (EAP) entitles you to 3 visits with a
        licensed therapist or psychologist, at no extra cost. Simply answer a
        few questions to activate this benefit.
      </div>
      <div className={cn(style.formContainer)}>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formTitle)}>
            Do any of these apply to you? <small>(Required)</small>
          </div>
          <div>
            <Form.Select aria-label="Select one to continue">
              <option value="">Please select one to continue</option>
              {data.step1.options1.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        </div>
        <div className={cn(style.formItem)}>
          <div className={cn(style.formTitle)}>
            What is your most pressing concern? <small>(Required)</small>
          </div>
          <div>
            <Form.Select aria-label="Select one to continue">
              <option value="">Please select one to continue</option>
              {data.step1.options2.map((item, index) => {
                return (
                  <option key={index} value={item}>
                    {item}
                  </option>
                );
              })}
            </Form.Select>
          </div>
        </div>
      </div>
      <div className={cn(style.stepButtonContainer)}>
        <div className={cn(style.continueButton)}>
          <Button onClick={onContinue} text={"Continue"} />
        </div>
      </div>
    </div>
  );
};
