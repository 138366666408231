import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Arrow from "assets/icons/down-arrow.svg";
import { LinkButton } from "atom/Anchor";
import Button from "atom/Button";
import Icon from "atom/Icon";
import PageCenter from "atom/PageCenter";
import OptumLogo from "assets/optum-logo.svg";
import MenuIcon from "assets/icons/menu.svg";
import CloseIcon from "assets/icons/x.svg";
import ModalContact from "molecule/ModalContact";
import ModalGethelp from "molecule/ModalGethelp";
import PrimaryLinks from "../PrimaryLinks";
import Sidebar from "../Sidebar";
import style from "./style.scss";
import cn from "classnames";

const primaryLinks = [
  { label: "Home", url: "/#Home" },
  { label: "Health journeys", url: "/#Health journeys" },
  { label: "Articles", url: "/Articles" },
  { label: "FAQs", url: "/#FAQs" },
];

const RightSide = ({
  onOpenSettings,
  user = "user0",
  setShowContact,
  setShowHelp,
}) => (
  <div className={cn(style.sidebar)}>
    <LinkButton
      className={cn(style.linkButton)}
      onClick={() => setShowContact(true)}
    >
      Help
    </LinkButton>
    <LinkButton className={cn(style.linkButton)} onClick={() => null}>
      Language <Arrow />
    </LinkButton>
    <LinkButton onClick={onOpenSettings} className={cn(style.linkButton)}>
      UnknownPerson <Arrow />
    </LinkButton>
    <div className={cn(style.items)}>
      24/7 emotional support&nbsp;&nbsp;
      <Button
        light={true}
        onClick={() => setShowHelp(true)}
        className={cn(style.button)}
        TagName="div"
      >
        <Icon icon={"phone"} /> Get help now
      </Button>
    </div>
  </div>
);

const Header = ({ onOpenSettings, onChatbot, user = "user0" }) => {
  let location = useLocation();
  const [open, setOpen] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [onTop, setOnTop] = useState(true);

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY >= 500 && onTop) setOnTop(false);
      else if (window.scrollY <= 500 && !onTop) setOnTop(true);
    };

    return () => (window.onscroll = null);
  });

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <PageCenter
        rootClass={cn(style.root)}
        contentClass={cn(style.content)}
        id={"header"}
      >
        <Sidebar
          open={open}
          links={primaryLinks}
          other={
            <RightSide
              {...{ onOpenSettings, user, setShowContact, setShowHelp }}
            />
          }
        />
        <div className={cn(style.dock, !onTop && style.visible)}>
          <PrimaryLinks links={primaryLinks} location={location} />
          <Button onClick={onChatbot} className={cn(style.start)} TagName="div">
            Get guided help
          </Button>
          <div className={cn(style.divider)} />
          <div onClick={onOpenSettings} className={cn(style.settings)} />
        </div>
        <div className={cn(style.header)}>
          <div className={cn(style.menu)}>
            {open ? (
              <CloseIcon onClick={() => setOpen(false)} />
            ) : (
              <MenuIcon onClick={() => setOpen(true)} />
            )}
          </div>
          <div className={cn(style.logo)}>
            <Link to={"/"}>
              <OptumLogo />
            </Link>
          </div>
          <div className={cn(style.helplinks)}>
            <LinkButton
              className={cn(style.linkButton)}
              onClick={() => setShowContact(true)}
              TagName="div"
            >
              Help
            </LinkButton>
            <div className={cn(style.divider)} />
            <LinkButton
              className={cn(style.linkButton)}
              onClick={() => null}
              TagName="div"
            >
              Language <Arrow />
            </LinkButton>
            <div className={cn(style.divider)} />
            <div onClick={onOpenSettings} className={cn(style.linkButton)}>
              UnknownPerson <Arrow />
            </div>
          </div>
        </div>
        <div className={cn(style.header_bottom)}>
          <PrimaryLinks links={primaryLinks} location={location} />
          <div className={cn(style.items)}>
            24/7 emotional support
            <Button
              light={true}
              onClick={() => setShowHelp(true)}
              className={cn(style.button)}
              TagName="div"
            >
              <Icon icon={"phone"} /> Get help now
            </Button>
          </div>
        </div>
      </PageCenter>
      {showHelp && <ModalGethelp onClose={() => setShowHelp(false)} />}
      {showContact && <ModalContact onClose={() => setShowContact(false)} />}
    </>
  );
};

export default Header;
