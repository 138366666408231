export default [
  {
    id: "benefits",
    message:
      "Learning more about your options is a great place to start your wellness journey!",
    trigger: "benefits-2",
  },
  {
    id: "benefits-2",
    message: "Have a great rest of your day 😀",
    trigger: "resources",
    //Benefits card
  },
];
