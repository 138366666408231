import React from "react";
import PageCenter from "atom/PageCenter";
import { Heading } from "atom/Typography";
import SectionCard from "./SectionCard";
import style from "./style.scss";
import cn from "classnames";

const healthJourney_cards = [
  {
    category: "Self Care",
    title: "I want to improve myself",
    content:
      "A comprehensive set of self-care tools for mental well-being provides individuals with a powerful arsenal to nurture their emotional and mental health.\n\nThese self-care tools promote self-awareness and resilience. They include resources like informative articles, engaging webinars, and user-friendly apps that offer knowledge and strategies to manage stress, anxiety, and emotional well-being.",
    button: "See Self Care",
    image: {
      src: require("/assets/images/HJ1.png"),
      placeholder: require("/assets/images/HJ1_thumb.png"),
      height: 408,
      width: 408,
    },
  },
  {
    category: "Coaching",
    title: "I want one-on-one support",
    content:
      "In the realm of Emotional Wellbeing Solution (EWS) coaching tools, you gain access to various resources to enhance personal and professional growth.\n\nEWS coaching often begins with individualized assessments to identify areas of improvement. Then, there are one-on-one coaching sessions with experienced professionals to set goals, overcome obstacles, and achieve career aspirations.",
    button: "See Coaching",
    image: {
      src: require("/assets/images/HJ2.png"),
      placeholder: require("/assets/images/HJ2_thumb.png"),
      height: 408,
      width: 408,
    },
  },
  {
    category: "Therapy",
    title: "I want to connect with a therapist",
    content:
      "Therapy is a crucial component of mental health support. It encompasses various approaches, from one-on-one counseling to teletherapy, providing a safe, confidential space for meaningful conversations with trained therapists.\n\nUsing our tools you can find therapy services through mental health professionals, clinics, or even online platforms that offer teletherapy sessions.",
    button: "See Theraphy",
    image: {
      src: require("/assets/images/HJ3.png"),
      placeholder: require("/assets/images/HJ3_thumb.png"),
      height: 408,
      width: 408,
    },
  },
  {
    category: "Manager Tools",
    title: "I want resources for everyday challenges",
    content:
      "Our robust Management Consultation Service empowers managers with unwavering confidence and an extensive arsenal of resources, enabling them to deftly navigate the intricacies of people management.\n\nThis discreet and confidential telephone service serves as an invaluable tool for managers.This ensures that managers have access to immediate assistance and on-the-ground guidance when facing unforeseen challenges.",
    button: "See Manager Tools",
    image: {
      src: require("/assets/images/HJ4.png"),
      placeholder: require("/assets/images/HJ4_thumb.png"),
      height: 408,
      width: 408,
    },
  },
  {
    category: "Other EWS Benefits",
    title: "I want to access my benefits",
    content: `Among the diverse array of "EAP/EWS Benefits", individuals gain access to an array of essential resources designed to streamline their experience and ensure prompt access to support.\n\nThe "Click to call" feature offers immediate access to assistance, eliminating unnecessary delays and ensuring timely support during critical moments. It's all about making support readily available when needed.`,
    button: "See Other EWS/EAP Benefits",
    image: {
      src: require("/assets/images/HJ5.png"),
      placeholder: require("/assets/images/HJ5_thumb.png"),
      height: 408,
      width: 408,
    },
  },
];

export default ({ onClick }) => (
  <PageCenter id={"Health journeys"} contentClass={cn(style.content)}>
    <Heading type="h2" centered>
      Health Journeys
    </Heading>
    {/* <p>
      Discover our meticulously tailored health journeys, designed to enhance
      your emotional wellbeing. Expertly curated, these resources guide you
      towards improved emotional wellness. Choose the ones that best align with
      your needs and embark on your personal, meaningful journey to mental
      well-being.
    </p> */}
    <SectionCard items={healthJourney_cards} onClick={onClick} />
  </PageCenter>
);
