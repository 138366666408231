import user1 from "./user-1.png";
import user2 from "./user-2.png";
import user3 from "./user-3.png";
import user4 from "./user-4.png";
import user5 from "./user-5.png";
import user6 from "./user-6.png";
import user7 from "./user-7.png";
import user8 from "./user-8.png";
import user9 from "./user-9.png";
import user10 from "./user-10.png";
import user11 from "./user-11.png";
import user12 from "./user-12.png";
import user13 from "./user-13.png";
import user14 from "./user-14.png";
import user15 from "./user-15.png";
import user16 from "./user-16.png";
import user17 from "./user-17.png";
import user18 from "./user-18.png";
import chat0 from "./chat-0.png";
import chat1 from "./chat-1.png";
import chat2 from "./chat-2.png";
import chat3 from "./chat-3.png";
import chat4 from "./chat-4.png";
import chat5 from "./chat-5.png";
import chat6 from "./chat-6.png";
import chat7 from "./chat-7.png";
import chat8 from "./chat-8.png";
import chat9 from "./chat-9.png";
import chat10 from "./chat-10.png";
import chat11 from "./chat-11.png";
import chat12 from "./chat-12.png";
import chat13 from "./chat-13.png";
import chat14 from "./chat-14.png";
import chat15 from "./chat-15.png";
import chat16 from "./chat-16.png";
import chat17 from "./chat-17.png";
import chat18 from "./chat-18.png";

export default {
  user1,
  user2,
  user3,
  user4,
  user5,
  user6,
  user7,
  user8,
  user9,
  user10,
  user11,
  user12,
  user13,
  user14,
  user15,
  user16,
  user17,
  user18,
  chat0,
  chat1,
  chat2,
  chat3,
  chat4,
  chat5,
  chat6,
  chat7,
  chat8,
  chat9,
  chat10,
  chat11,
  chat12,
  chat13,
  chat14,
  chat15,
  chat16,
  chat17,
  chat18,
};
