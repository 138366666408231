import React, { useState, useRef, useEffect } from "react";
import Carousel from "atom/Carousel";
import style from "./style.scss";
import cn from "classnames";
import BannerContent from "molecule/BannerContent";

const Banner = ({
  background,
  content,
  home,
}) => (
  <div
    className={cn(
      style.root,
      content ? style.withContent : null,
      home ? style.rootHome : ""
    )}
    style={{ backgroundImage: `url(${background})` }}
  ></div>
);

export default ({ home, background, ariaLabelPause }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentBanner_prepare, setCurrentBanner_prepare] = useState(0)
  const slider = useRef(null);
  const [sliderPaused, setSliderPaused] = useState(false);
  const [sliderFocus, setSliderFocus] = useState(false);

  const [contentBoxes, setContentBoxes] = useState([]);

  useEffect(() => {
    // initialize contentBoxes with the first element of home
    if (home?.[0]) {
      setContentBoxes([home?.[0]]);
    }
  }, [home]);

  useEffect(() => {
    /* 
      put home[currentBanner_prepare] to the top of the array contentBoxes
      remove last element of contentBoxes 
    */
    if (home) {
      setContentBoxes((prev) => {
        const newContentBoxes = [...prev];
        // can be null
        newContentBoxes.unshift(home?.[currentBanner_prepare] || null);
        newContentBoxes.pop();
        return newContentBoxes;
      });
    }
  }, [currentBanner_prepare]);

  return (
    <>
      <Carousel
        className={cn(style.banner, home ? style.bannerHome : "")}
        ref={slider}
        paused={sliderPaused}
        onPaused={setSliderPaused}
        onBeforeChange = {(i) => {setCurrentBanner_prepare(i)}}
        pauseAria={ariaLabelPause}
        focused={sliderFocus}
        currentIndex={currentIndex}
        setCurrentIndex = {setCurrentIndex}
        settings={{
          dots: true,
          fade: true,
          customPaging: (_i) => <div style={{ height: 8, width: 28 }} />,
          afterChange: setCurrentIndex,
        }}
      >
        {home ? (
          home.map((value, i) => (
            <Banner
              {...value}
              key={i}
              isVisible={currentIndex === i}
              onFocus={() => {
                setSliderFocus(true);
                // setSliderPaused(true)
              }}
              onBlur={() => {
                setSliderFocus(false);
                // setSliderPaused(false)
              }}
              home={home}
            />
          ))
        ) : (
          <Banner background={background} />
        )}
      </Carousel>
      <div className={cn(style.contentContainer)}>
        <div className={cn(style.contentCardList)}>
          {contentBoxes?.map((contentBox, i) => {
            if (!contentBox) return null;
            return <BannerContent key={`content_box_${i}`} {...contentBox} isActive={true}/>
          })}
        </div>
      </div>
    </>
  );
};
