import React, { useState } from "react";
import Button from "atom/Button";
import Modal from "atom/Modal";
import { Heading } from "atom/Typography";
import constants from "lib/constants";
import Form from "react-bootstrap/Form";
import style from "./style.scss";
import Icon from "atom/Icon";
import cn from "classnames";
import data from "./data.js";

import Sec_Step1 from "./Sec_Step1";
import Sec_Step2 from "./Sec_Step2";
import Sec_Step3 from "./Sec_Step3";
import Sec_Step4 from "./Sec_Step4";

const TITILES = [
  "Get your no-cost counselor visits!",
  "Next, some basic info",
  "Does everything look right?",
  "You’ve activated your EAP benefit for no-cost counseling visits!",
];

const STEP_ICONS = [
  {
    number: 1,
    text: "Getting started",
  },
  {
    number: 2,
    text: "Enter your info",
  },
  {
    number: 3,
    text: "Activate benefit",
  },
];

export default ({ onClose }) => {
  const [step, setStep] = useState(0);

  const handleContinue = () => {
    setStep(prev => prev + 1);
  };

  const handleBack = () => {
    setStep(prev => prev - 1);
  };

  return (
    <Modal
      id={"free account"}
      contentClass={cn(style.content)}
      {...{ onClose }}
    >
      <Heading type={"h3"} centered>
        {TITILES[step]}
      </Heading>

      {/* top 3 icons */}
      <div className={cn(style.stepIconsContainer)}>
        <div className={cn(style.stepIcons)}>
          {STEP_ICONS.map((icon, index) => {
            const isActive = index === step;
            const isFinished = index < step;

            const styles = [
              isActive ? style.stepIconActive : "",
              isFinished ? style.stepIconFinished : "",
            ];

            return (
              <React.Fragment key={index}>
                <div
                  className={cn(style.stepOneIconContainer, ...styles)}
                  key={index}
                >
                  <div className={cn(style.stepIconNumber)}>
                    <span>
                      {isFinished ? <Icon icon="checkmark" /> : icon.number}
                    </span>
                  </div>
                  <div className={cn(style.stepIconText)}>{icon.text}</div>
                </div>
                <div className={cn(style.horizontalLine)} />
              </React.Fragment>
            );
          })}
        </div>
      </div>

      {/* steps */}
      {step === 0 && <Sec_Step1 {...{ onContinue: handleContinue }} />}
      {step === 1 && (
        <Sec_Step2 {...{ onContinue: handleContinue, onBack: handleBack }} />
      )}
      {step === 2 && (
        <Sec_Step3 {...{ onContinue: handleContinue, onBack: handleBack }} />
      )}
      {step === 3 && <Sec_Step4 {...{ onClose }} />}

      <div>
        Want help with this process? Call{" "}
        <a href="tel:15555555555">1-555-555-5555</a> for assistance. You can
        reach us 24/7.
      </div>
    </Modal>
  );
};

// ============================== steps: ==============================
