import React, { useEffect, useState, useRef, createRef } from "react";
import Slider from "react-slick";
import cn from "classnames";
import Icon from "atom/Icon";
import style from "./style.scss";
import useDelayInput from "lib/hooks/useDelayInput";

export default React.forwardRef(
  (
    {
      currentIndex,
      setCurrentIndex = () => {},
      className,
      onBeforeChange = () => {},
      pauseAria,
      onPaused,
      children,
      settings,
      paused,
      focused,
      forceIndex = -1
    },
    ref
  ) => {

    const [speed, setSpeed] = useState(500)
    const dotRefs = useRef([]);
    const arrLength = children.length;
    dotRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => dotRefs.current[i] || createRef());

    useEffect(() => {
      paused || focused
        ? ref?.current?.slickPause()
        : ref?.current?.slickPlay();
    }, [paused, focused]);

    const handleDotClick = (i, event, callBack) => {
      callBack(event);
      dotRefs.current[i].current.focus();
    };

    const forceSwitchRelease = () => {
      setSpeed(500)
      ref?.current?.slickPlay();
    }
    const {onInput} = useDelayInput(() => {}, forceSwitchRelease)

    useEffect(() => {
      if (forceIndex !== -1) {
        // jump to
        setSpeed(0)
        ref?.current.slickGoTo(forceIndex);
        ref?.current?.slickPause()
      } else {
        // setSpeed(500)
        // ref?.current?.slickPlay();
        onInput()
      }
    }, [forceIndex]);

    const defaultSettings = {
      dots: false,
      infinite: true,
      arrows: false,
      speed,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      // focusOnSelect: true,
      pauseOnHover: true,
      pauseOnFocus: true,
      pauseOnDotsHover: true,
      autoplaySpeed: 4500,
      cssEase: "linear",
      beforeChange: (p, n) => {
        setCurrentIndex(n);
        onBeforeChange(n);
      },
      appendDots: (dots) => (
        <div>
          <button
            className={cn(style.pause)}
            onClick={() => {
              // paused?ref.current.slickPlay():ref.current.slickPause ()
              onPaused(!paused);
              // setPaused(!paused)
            }}
            aria-label={pauseAria}
          >
            <Icon
              className={cn(
                style.pauseIcon,
                paused ? style.onPause : style.offPause
              )}
              icon="pause"
            />
          </button>
          {dots?.map((e, i) => {
            return (
              <button
                tabIndex={0}
                ref={dotRefs.current[i]}
                onClick={(event) =>
                  handleDotClick(i, event, e.props.children?.props?.onClick)
                }
                className={cn(
                  style.dot,
                  e.props.className,
                  currentIndex === i ? style.activeDot : ""
                )}
                key={i}
                aria-label={i + 1}
              />
            );
          })}
        </div>
      ),
      ...settings,
    };
    
    return (
      <div className={cn(style.carousel, className)}>
        <Slider {...defaultSettings} ref={ref}>
          {children}
        </Slider>
      </div>
    );
  }
);
