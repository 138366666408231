import React, { useState } from "react";
import Button from "atom/Button";
import Modal from "atom/Modal";
import SelectCountry from "atom/SelectCountry";
import { Heading } from "atom/Typography";
import constants from "lib/constants";
import style from "./style.scss";
import cn from "classnames";

export default ({ onClose }) => {
  const [country, setCountry] = useState("");

  const handleCall = () => {
    onClose();
    window.location.href = `tel:${constants.NUMBER}`;
  };

  return (
    <Modal id={"help"} contentClass={cn(style.content)} {...{ onClose }}>
      <Heading type={"h3"} centered>
        Get help now?
      </Heading>
      <Heading type={"h5"} centered weight={500}>
        Call anytime for confidential help
      </Heading>
      <div className={style.country}>
        <SelectCountry
          handleChange={e => setCountry(e.target.value)}
          selected={country}
        />
      </div>
      <Button onClick={handleCall} text={"1-804-222-1111"} />
    </Modal>
  );
};
