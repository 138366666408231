import React from "react";
import { HashLink } from "react-router-hash-link";
import style from "./style.scss";
import cn from "classnames";

const PrimaryLinks = ({ links, location }) => {
  const active = decodeURI(location.hash);
  const home = location.pathname == "/" && active == "";

  const scrollWidthOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -130;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  return (
    <div className={cn(style.content)}>
      {links.map(({ label, url }, i) => (
        <div
          key={i}
          className={cn(
            style.link,
            home && label == "Support Finder"
              ? style.active
              : active == "#" + label
              ? style.active
              : null
          )}
        >
          <HashLink smooth to={url} scroll={el => scrollWidthOffset(el)}>
            {label}
          </HashLink>
        </div>
      ))}
    </div>
  );
};

export default PrimaryLinks;
