import React, { useEffect, useState } from "react";
import moment from "moment";
import ChatbotHome from "./ChatbotHome";
import ModalResource from "molecule/ModalResource";
import SettingsProvider from "provider/SettingsProvider";
import Modal from "atom/Modal";
import Bot from "./Bot";
import style from "./style.scss";
import cn from "classnames";
import { Heading } from "atom/Typography";
import Button from "atom/Button";
import Icon from "atom/Icon";
import NoConvo from "./NoConvo";

const bubbleStyle = {
  borderRadius: 8,
  color: "var(--palette-dark-blue)",
  padding: "6px 12px",
  boxShadow: "none",
};

export const Bubble = ({ text }) => (
  <div className={cn(style.evalBubble)} style={bubbleStyle}>
    {text}
  </div>
);

export const ChatModal = ({ children, id, finalClose, contentClass }) => {
  return (
    <Modal
      id={id}
      onClose={finalClose}
      containerClass={cn(style.chatbot_container)}
      contentClass={cn(style.chatbot_modal, contentClass)}
      isCloseButton={false}
      withClose={false}
      clickOutside={true}
      withSlide={true}
    >
      <div className={cn(style.divider)} />
      <div className={cn(style.content)}>{children}</div>
    </Modal>
  );
};

const Options = ({
  option,
  open,
  setOpen,
  newConvo,
  saveConvo,
  deleteConvo,
}) => {
  return (
    <div className={cn(style.options)}>
      <Icon icon="options" className={cn(style.svg)} onClick={setOpen} />
      {open && (
        <div className={cn(style.modal, option == "options" && style.main)}>
          <label>{option == "options" ? "Options" : "Actions"}</label>
          {option == "options" && (
            <div className={cn(style.action)} onClick={newConvo}>
              <Icon icon={"info"} /> Start a new journey
            </div>
          )}
          <div className={cn(style.action)} onClick={saveConvo}>
            <Icon icon={"info"} /> Save conversation
          </div>
          <div className={cn(style.line)} />
          <div className={cn(style.action, style.delete)} onClick={deleteConvo}>
            <Icon icon={"info"} /> Delete
          </div>
        </div>
      )}
    </div>
  );
};

const Chatbot = ({ show, onClose }) => {
  const finalChatbot = SettingsProvider.getChatbot();
  const [resourceKey, setResourceKey] = useState(null);
  const [home, setHome] = useState(true);
  const [chat, setChat] = useState(false);
  const [modalHome, setModalHome] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [activeChat, setActiveChat] = useState();
  const [activeOption, setActiveOption] = useState();

  useEffect(() => {
    //check if there is existing sesh
    // if (true && home) {
    //   //save to state existingsesh
    //   setConversations;
    //   setHome(false);
    //   setChat(true);
    // }
  }, []);

  function saveConversation() {
    //save on local
  }

  function deleteConversation(id) {
    let filteredArray = conversations?.filter(item => item.id !== id);
    setConversations(filteredArray);
    localStorage.removeItem(id);
    if (activeChat == id) setActiveChat("");
    if (filteredArray?.length == 0) {
      setChat(false);
      setHome(true);
    }
  }

  function setOption(link) {
    if (home) {
      setHome(false);
      setChat(true);
    }

    if (modalHome) {
      setModalHome(false);
    }

    const convo = {
      id: "convo" + new Date(),
      type: link,
      date: new Date(),
    };

    setConversations(prev => [...prev, convo]);

    setActiveChat(convo.id);
  }

  function finalClose() {
    // add expire to cached unsave local storage
    setActiveChat("");
    onClose();
  }

  function setMiniOption(optionkey) {
    if (optionkey != activeOption) setActiveOption(optionkey);
    else if (activeOption != "") setActiveOption("");
  }

  return (
    show && (
      <>
        <ChatModal id={"chatmain"} finalClose={finalClose}>
          {home && <ChatbotHome onClick={setOption} />}
          {chat && (
            <div className={cn(style.chats)}>
              <div className={cn(style.top)}>
                <Heading type="h4">Welcome back!</Heading>
                <Button light onClick={() => setMiniOption("options")}>
                  Options
                  <Options
                    option={activeOption}
                    open={activeOption == "options"}
                    setOpen={null}
                    newConvo={() => setModalHome(true)}
                    deleteConvo={() => deleteConversation(activeChat)}
                  />
                </Button>
              </div>
              <div className={cn(style.bottom)}>
                <div className={cn(style.sessions)}>
                  {conversations?.map(({ id, type, date }, a) => {
                    // const date = localStorage.getItem(id);
                    const dateTimeAgo = moment(date).fromNow();

                    console.warn(dateTimeAgo);
                    return (
                      <div
                        key={a}
                        className={cn(
                          style.item,
                          activeChat == id && style.active
                        )}
                      >
                        <div
                          className={cn(style.item_left)}
                          onClick={() => setActiveChat(id)}
                        >
                          <div className={cn(style.item_top)}>
                            <div className={cn(style.item_title)}>
                              <div className={cn(style.icon)} /> New
                              conversation
                            </div>
                            <label>{"now"}</label>
                          </div>
                          <p>
                            {type == "pathways"
                              ? "Mental Wellbeing"
                              : "Get answers and find help quickly"}
                          </p>
                        </div>
                        <div className={cn(style.item_right)}>
                          <Options
                            option={activeOption}
                            open={activeOption == id}
                            deleteConvo={() => deleteConversation(id)}
                            setOpen={() => setMiniOption(id)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={cn(style.conversations)}>
                  {!activeChat ? (
                    <NoConvo onClick={() => setModalHome(true)} />
                  ) : (
                    conversations?.map(({ id, type }, e) => (
                      <Bot
                        key={e}
                        id={id}
                        triggerStep={type == "pathways" ? "pathways" : null}
                        setResourceKey={setResourceKey}
                        onClose={() => deleteConversation(id)}
                        className={cn(
                          activeChat == id ? style.visible : style.hidden
                        )}
                      />
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
        </ChatModal>
        {modalHome && (
          <ChatModal id={"chathome"}>
            <ChatbotHome onClick={setOption} />
          </ChatModal>
        )}
        <ModalResource
          resourceKey={resourceKey}
          onClose={() => setResourceKey(null)}
        />
      </>
    )
  );
};

export default Chatbot;
