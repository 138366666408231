import React from "react";
import style from "./style.scss";
import cn from "classnames";

export default ({ text, children, onClick, type, light, className }) => (
  <button
    {...{ type, onClick }}
    className={cn(style.button, light && style.light, className)}
  >
    {children || text}
  </button>
);
