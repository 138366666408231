import React, { useState } from "react";
import cn from "classnames";

import Form from "react-bootstrap/Form";

// styles
import style from "./style.scss";

const languages = [
  "Arabic",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Dutch",
  "English (UK)",
  "English (US)",
  "French",
  "French (Canada)",
  "German",
  "Hebrew",
  "Hindi",
  "Italian",
  "Japanese",
  "Korean",
  "Malay",
  "Polish",
  "Portuguese",
  "Russian",
  "Spanish",
  "Swedish",
  "Thai",
  "Turkish",
];

export default ({ selected = "", handleChange, simple = false }) => (
  <Form.Select
    aria-label="Select a Language"
    className={cn(
      style.root,
      selected ? style.has_value : style.no_value,
      simple && style.simple
    )}
    value={selected}
    onChange={handleChange}
  >
    <option disabled={true} value="">
      {simple ? "Language" : "Select a language"}
    </option>
    {languages
      .sort((a, b) => (a > b ? 1 : -1))
      .map((item, index) => {
        return (
          <option key={index} value={item}>
            {item}
          </option>
        );
      })}
  </Form.Select>
);
