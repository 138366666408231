import React, { useState } from "react";
import cn from "classnames";
import Modal from "atom/Modal";
import SelectCountry from "atom/SelectCountry";

// styles
import style from "./style.scss";
import { Heading } from "atom/Typography";

const contacts = [
  {
    title: "Main",
    subdetails: [
      {
        phone: "1-804-222-1111",
        sublabel: "24/7",
      },
      {
        phone: "+44-1865-394-074",
        sublabel: "English",
      },
    ],
  },
  {
    title: "Alternative",
    subdetails: [
      {
        phone: "+44-1865-394-074",
        sublabel: "International",
      },
    ],
  },
  {
    title: "Website",
    subdetails: [
      {
        website: "livewell.optum.com",
        link:
          "https://livewell.optum.com/?accesstoken=B4469F92-9BE7-11EB-AC53-CBBFBA87E4EC",
      },
    ],
  },
];

export default ({ className, show, onClose }) => {
  const [country, setCountry] = useState("");

  return (
    <Modal
      id={"contact"}
      contentClass={cn(className, style.root)}
      {...{ onClose }}
    >
      <Heading type={"h2"} centered>
        Contact us
      </Heading>
      <div>
        We’re available 24/7 to provide confidential help with any of life’s
        challenges.
        <br />
        <br />
        <b>NOTE:</b> Please confirm your location using the location dropdown
        before contacting.
      </div>
      <div className={cn(style.country)}>
        <SelectCountry
          handleChange={e => setCountry(e.target.value)}
          selected={country}
        />
      </div>
      <div className={cn(style.items)}>
        {contacts.map(({ title, subdetails }, i) => (
          <div key={i} className={cn(style.item)}>
            <strong>{title}</strong>
            {subdetails.map(({ phone, sublabel, website, link }, g) => (
              <div className={cn(style.subdetail)}>
                {phone && (
                  <>
                    <a href={`tel:${phone}`}>{phone}</a>
                    <span className={cn(style.label)}>{sublabel}</span>
                  </>
                )}
                {website && (
                  <a target="_blank" href={link}>
                    {website}
                  </a>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </Modal>
  );
};
