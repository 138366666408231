import React from "react";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";
import Icon from "atom/Icon";
import Button from "atom/Button";

export default ({ onClick }) => (
  <div className={cn(style.noConvo)}>
    <Icon icon="convo" />
    <Heading type={"h4"} centered>
      No conversation selected
    </Heading>
    <p>Select a conversation to begin, or start a new one</p>
    <Button onClick={onClick}>Start a new journey</Button>
  </div>
);
