import React from "react";
import Anchor, { LinkButton } from "atom/Anchor";
import Icon from "atom/Icon";
import Chip from "atom/Chip";
import ProgressiveImage from "atom/ProgressiveImage";
import { Heading } from "atom/Typography";
import style from "./style.scss";
import cn from "classnames";

const Card = ({
  image,
  chip,
  icon,
  title,
  content,
  side,
  smaller,
  button,
  className,
  onClick,
}) => (
  <div
    className={cn(style.card, side && style.side, className)}
    {...{ onClick }}
  >
    {!image && (
      <div className={cn(style.icons)}>
        <Icon icon="bookmark" />
        <Icon icon="cardarrow" className={cn(style.arrow)} />
      </div>
    )}
    {image ? (
      <ProgressiveImage {...image} />
    ) : (
      <div className={cn(style.img)} />
    )}
    <div className={cn(style.text)}>
      <div className={cn(style.upper)}>
        {chip && <Chip text={chip} />}
        {icon && <Icon icon={icon} />}
        <Heading
          type={"h4"}
          className={cn(style.header, smaller && style.smaller)}
        >
          {title}
        </Heading>
        <p>{content}</p>
      </div>
      {button &&
        (button?.link ? (
          <Anchor href={button.link}>{button.label}</Anchor>
        ) : (
          <LinkButton to={button.to}>{button.label}</LinkButton>
        ))}
    </div>
  </div>
);

export default Card;
