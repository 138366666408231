import React, { createContext, useEffect, useState, useContext } from "react";
import theme from "data/theme.js";
// import { GeneralContext } from "provider/GeneralProvider";

export const ThemeContext = createContext(null);

export const ThemeProvider = ({ children, ...props }) => {
  const [vars, setVars] = useState({});
  const [logo, setLogo] = useState("");
  const [data, setData] = useState({})

  // const contextData = useContext(GeneralContext);
  //const theme_overrides = contextData?.content?.theme?.theme_overrides || {};

  useEffect(() => {
    const brand = data?.content?.brand
    
    const _theme = theme[brand] || theme.default;
    if (_theme) {
      setVars(_theme.vars);
      setLogo(_theme.logo);
    }
  }, [data?.content?.brand]);

  const themeDefault = {};
  return (
    <ThemeContext.Provider value={{logo, data, setData}}>
      <div style={{ ...themeDefault, ...vars }} {...props}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
