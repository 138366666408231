import React, { useState } from "react";
import cn from "classnames";
import Form from "react-bootstrap/Form";

import iconEnum from "assets/icons";
import Button from "atom/Button";
import Input from "atom/Input";
import SelectCountry from "atom/SelectCountry";
import LoginBox from "molecule/LoginBox";
import LoginQuestions from "molecule/LoginQuestions";

import SettingsProvider from "provider/SettingsProvider";

// styles
import style from "./style.scss";
import constants from "lib/constants";

const validate = ({ nickname }) => {
  const errors = [];
  if (nickname === "SparkyJazzy") {
    errors.push("Nickname is taken");
  }

  if (nickname === "") {
    errors.push("Nickname is empty");
  }

  return errors;
};

// upper case first letter
const generatedNickNames = [
  "SparkyJazzy",
  "CountryCabbage",
  "AmazingSpider",
  "CorgiLoaf",
  "FreeCoffee",
];

const randomIntFromInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export default ({ onLogin, onSubmit }) => {
  const [nickname, setNickname] = useState("");
  const [formInput, setFormInput] = useState([]);

  const getRandomNickname = () => {
    const index = randomIntFromInterval(1, 5);
    setNickname(generatedNickNames[index - 1]);
  };

  const [validation, setValidation] = useState([]);

  const handleSubmit = e => {
    e.preventDefault();

    const errors = validate({ nickname });
    if (errors.length === 0) {
      SettingsProvider.setCountryLanguage(formInput);
      SettingsProvider.setAvatars({ user: "user0", chat: "chat-0" });
      onSubmit(nickname);
    } else {
      setValidation(errors);
    }
  };

  const handleKeyDown = e => {
    if (e.code === "Enter") {
      handleSubmit();
    }
  };

  const handleChange = (e, name) => {
    setFormInput(prevState => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(nickname);
    alert("Copied nickname to clipboard");
  };

  return (
    <LoginBox boxStyle={style.register_box}>
      <div className={cn(style.register_header)}>
        <h2 className={cn(style.welcome_label)}>Sign Up</h2>
        <p className={cn(style.nickname_label)}>
          Create an account with a nickname
        </p>
      </div>
      <form className={cn(style.register_form)} onSubmit={e => handleSubmit(e)}>
        <div className={cn(style.register_input)}>
          <Form.Label htmlFor="location">LOCATION</Form.Label>
          <SelectCountry
            handleChange={e => handleChange(e, "country")}
            selected={formInput["country"]}
          />
        </div>
        {/* <div className={cn(style.register_input)}>
          <Form.Label htmlFor="language">Language</Form.Label>
          <SelectLanguage
            handleChange={e => handleChange(e, "language")}
            selected={formInput["language"]}
          />
        </div> */}
        <div className={cn([style.register_input, style.nickname_input])}>
          <Form.Label htmlFor="nickname">NICKNAME</Form.Label>
          <div className={style.nickname_wrapper}>
            <span className={cn(style.generate_nick_wrapper)}>
              <span title="Copy nickname" onClick={handleCopy}>
                {React.createElement(iconEnum["copy"])}
              </span>
              <a
                href="#"
                className={cn(style.generate_nick)}
                onClick={e => {
                  e.preventDefault();
                  getRandomNickname();
                }}
                title="Generate nickname"
              >
                {React.createElement(iconEnum["arrowsRotateSolid"])}
              </a>
            </span>
            <Input
              type="text"
              id="nickname"
              aria-describedby="nickname_err"
              value={nickname}
              maxLength={18}
              onChange={e => {
                setValidation([]);
                setNickname(e.target.value);
              }}
              placeholder="Choose your nickname"
              validation={validation}
              onKeyDown={handleKeyDown}
            />
          </div>

          <p className={style.description}>
            Choose a nickname and avoid sharing personal information. Generate a
            random nickname using the button.
          </p>
        </div>

        {/* <div className={cn([style.register_input, style.nickname_input])}>
          <div className={cn(style.nickname_wrapper)}>
            <div>
              <span>Your nickname is:</span>
              <span className={style.nickname_display}>{nickname}</span>
            </div>

            <Form.Control
              type="hidden"
              id="nickname"
              aria-describedby="nickname"
              value={nickname}
              disabled={true}
            />

            <span className={cn(style.generate_nick_wrapper)}>
              <a
                href="#"
                className={cn(style.generate_nick)}
                onClick={e => {
                  e.preventDefault();
                  getRandomNickname();
                }}
              >
                {React.createElement(iconEnum["arrowsRotateSolid"])}
              </a>
            </span>
          </div>
        </div> */}

        <div className={cn(style.register_button_wrapper)} aria-live="polite">
          <Button text="Sign Up" type="submit" />
        </div>
        <div className={cn(style.login_wrapper)}>
          <span>Already have an account? </span>
          <a
            href="#"
            className={cn(style.back_to_login)}
            onClick={e => {
              e.preventDefault();
              onLogin();
            }}
          >
            Sign In
          </a>
        </div>
        <LoginQuestions />
      </form>
    </LoginBox>
  );
};
