import React from "react";
import useWindowDimensions from "lib/hooks/useWindowDimensions";
import HomeBanner_desktop from "../HomeBanner_desktop";
import HomeBanner_mobile from "../HomeBanner_mobile";




export default (props) => {
  const { isMobile } = useWindowDimensions();
  if (isMobile) {
    return <HomeBanner_mobile {...props} />;
  } else {
    return <HomeBanner_desktop {...props} />;
  }
};
