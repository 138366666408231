export default [
  {
    id: "journeys-selfcare",
    message:
      "Would you like to see some tools that can help with self-discovery and self-improvement of your mental and physical wellbeing?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-therapy",
    message:
      "Would you like to see the available therapeutic options available to you?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-financial",
    message:
      "Would you like to explore our financial and legal resources to access expert guidance for a secure future?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-coaching",
    message:
      "Would you like to see some coaching solutions designed to help enhance your skills, career, and personal growth?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-management",
    message:
      "Would you like to explore resources and counseling services designed to support you in your role as manager?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-peer",
    message:
      "Would you like to connect with others who understand what you're going through?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-ewsbenefits",
    message:
      "Would you like to explore resources to strengthen you family bonds or explore other EWS resources available to you?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-literacy",
    message:
      "Would you like access to a wealth of reliable information to make informed decisions about your health and well-being?",
    trigger: "journeys-options",
  },
  {
    id: "journeys-options",
    options: [
      {
        value: 1,
        label: "Show me",
        trigger: ({ steps }) => {
          if (steps["journeys-selfcare"]) return "journey-resources-selfcare";
          else if (steps["journeys-therapy"])
            return "journey-resources-therapy";
          else if (steps["journeys-financial"])
            return "journey-resources-financial";
          else if (steps["journeys-coaching"])
            return "journey-resources-coaching";
          else if (steps["journeys-management"])
            return "journey-resources-management";
          else if (steps["journeys-peer"]) return "journey-resources-peer";
          else if (steps["journeys-ewsbenefits"])
            return "journey-resources-ewsbenefits";
          else return "journey-resources-literacy";
        },
      },
      { value: 2, label: "No thanks", end: true },
    ],
  },
];
