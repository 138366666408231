import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import NicknameProvider from "provider/NicknameProvider";
import Form from "react-bootstrap/Form";
import SelectLanguage from "atom/SelectLanguage";
import Login from "molecule/Login";
import Register from "molecule/Register";
import MenuIcon from "assets/icons/menu.svg";
import CloseIcon from "assets/icons/x.svg";

// styles
import style from "./style.scss";
import SettingsProvider from "provider/SettingsProvider";
import Sidebar from "molecule/Sidebar";

export default () => {
  const [mode, setMode] = useState("login");
  const [open, setOpen] = useState(false);
  const [language, setLanguage] = useState("");

  let navigate = useNavigate();

  const handleSubmit = value => {
    NicknameProvider.setNickname(value);
    navigate("/");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [mode]);

  return (
    <div className={cn(style.nickname_wrapper)}>
      <div className={cn(style.menu_modal, open ? style.open : style.close)}>
        <SelectLanguage
          handleChange={e => setLanguage(e.target.value)}
          selected={language}
        />
      </div>
      <div className={cn(style.bg)}>
        <div className={cn(style.menu)}>
          {open ? (
            <CloseIcon onClick={() => setOpen(false)} />
          ) : (
            <MenuIcon onClick={() => setOpen(true)} />
          )}
        </div>
        <div className={cn(style.background_top)}>
          <div className={cn(style.register_input)}>
            <SelectLanguage
              handleChange={e => setLanguage(e.target.value)}
              selected={language}
            />
          </div>
        </div>
        <div className={cn(style.background_image)} />
      </div>
      {mode === "login" && (
        <Login
          onSubmit={handleSubmit}
          onRegister={() => {
            setMode("register");
          }}
        />
      )}
      {mode === "register" && (
        <Register
          onSubmit={handleSubmit}
          onLogin={() => {
            setMode("login");
          }}
        />
      )}
    </div>
  );
};
